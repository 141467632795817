import { UserProfile, extractInitialsFromNames, getUserFullName } from '@/common';
import Link from 'next/link';
import { FC, Ref } from 'react';
import { Avatar, AvatarFallback, AvatarImage, cn } from 'ui-lib';

export type UserAvatarProps = {
  className?: string;
  userProfile?: UserProfile;
  focusRef?: Ref<any>;
};

export const UserAvatar: FC<UserAvatarProps> = ({ className, userProfile, focusRef }) => {
  if (!userProfile) {
    return null;
  }

  const userId = userProfile.userId;

  const fullName = getUserFullName(userProfile.user);
  const initials = extractInitialsFromNames(userProfile.user);

  return (
    <div className='flex flex-row items-center justify-start pr-2'>
      <Link href={`/dashboard/users/${userId}`} ref={focusRef ?? null}>
        <Avatar className={cn('h-12 w-12', className)}>
          <AvatarImage
            src={userProfile.userAvatar?.resizedDownloadUrl ?? userProfile.userAvatar?.downloadUrl ?? ''}
            alt={`avatar for ${fullName}`}
          />
          <AvatarFallback>{initials}</AvatarFallback>
        </Avatar>
      </Link>
    </div>
  );
};

import { FC } from 'react';
import { Alert, AlertDescription, AlertTitle, InfoCircledIcon, cn } from 'ui-lib';

export type InviteRequestsCountProps = {
  count: number;
  className?: string;
};

export const InviteRequestsCount: FC<InviteRequestsCountProps> = ({ className, count }) => {
  return (
    <div className={cn('', className)}>
      <Alert>
        <InfoCircledIcon className='h-4 w-4' />
        <AlertTitle>{count === 0 ? 'No invite requests' : 'Invite Requests pending'}</AlertTitle>
        <AlertDescription>
          You have <span className='font-semibold'>{count}</span> invite request{count === 1 ? '' : 's'} pending
        </AlertDescription>
      </Alert>
    </div>
  );
};

import { StepTypeEnum } from '@/common';
import { FC } from 'react';
import { ChatBubbleIcon, CheckSquareIcon, FileUpIcon, ScrollTextIcon } from 'ui-lib';

type StepIconProps = {
  stepType: StepTypeEnum;
  className?: string;
  width?: number;
  height?: number;
};

export const StepIcon: FC<StepIconProps> = ({ stepType, className, width, height }): JSX.Element => {
  const props: {
    className?: string;
    width?: number;
    height?: number;
  } = {};

  if (className?.length) {
    props.className = className;
  }

  if (Number.isInteger(width)) {
    props.width = width;
  }

  if (Number.isInteger(height)) {
    props.height = height;
  }

  if (stepType === StepTypeEnum.Task) {
    <CheckSquareIcon {...props} />;
  }

  if (stepType === StepTypeEnum.DocumentUpload) {
    return <FileUpIcon {...props} />;
  }

  if (stepType === StepTypeEnum.Form) {
    return <ScrollTextIcon {...props} />;
  }

  if (stepType === StepTypeEnum.Enquiry) {
    return <ChatBubbleIcon {...props} />;
  }

  return <CheckSquareIcon {...props} />;
};

import { Step, StepStatusEnum } from '@/common';

export const getStepStatusText = (step: Step): string => {
  return stepStatusToText(step.status ?? StepStatusEnum.Draft);
};

export const stepStatusToText = (status: StepStatusEnum): string => {
  if (status === StepStatusEnum.Draft) {
    return 'Draft';
  }

  if (status === StepStatusEnum.Published) {
    return 'Published';
  }

  return status;
};

export const getStepBadgeVariant = (step: Step): 'default' | 'secondary' | 'outline' => {
  let badgeVariant: 'default' | 'secondary' | 'outline' = 'default';

  if (step.status === StepStatusEnum.Draft) {
    badgeVariant = 'outline';
  }

  return badgeVariant;
};

'use client';

import { Route } from 'next';
import Link from 'next/link';
import { Separator, Typography } from 'ui-lib';

interface FooterLink {
  href: Route<any>; // https://github.com/vercel/next.js/issues/47689
  label: string;
}

interface FooterSection {
  section: string;
  links: FooterLink[];
}

const footerLinks: FooterSection[] = [
  {
    section: 'Quick Links',
    links: [
      {
        href: `/dashboard` satisfies Route<string>,
        label: 'Dashboard',
      },
      {
        href: `https://www.matterhive.co.uk/blog`,
        label: 'Blog',
      },
      {
        href: `https://www.matterhive.co.uk`,
        label: 'For conveyancers',
      },
      {
        href: `https://www.matterhive.co.uk/clients`,
        label: 'For clients',
      },
    ],
  },
  {
    section: 'Support',
    links: [
      {
        href: `https://www.matterhive.co.uk/contact`,
        label: 'Contact',
      },
      {
        href: `https://www.matterhive.co.uk/support`,
        label: 'Support',
      },
    ],
  },
  {
    section: 'Resources',
    links: [
      {
        href: `https://www.matterhive.co.uk/privacy-policy`,
        label: 'Privacy Policy',
      },
      {
        href: `https://www.matterhive.co.uk/cookie-policy-uk`,
        label: 'Cookies Policy',
      },
      {
        href: `https://www.matterhive.co.uk/terms-and-conditions`,
        label: 'Terms & Conditions',
      },
      {
        href: `https://www.matterhive.co.uk/gdpr-policy`,
        label: 'GDPR',
      },
    ],
  },
];

export const FooterLinks = () => {
  return (
    <nav className=' container w-full'>
      <Separator orientation='horizontal' />
      <div className='grid grid-cols-2 items-start justify-center gap-8 p-8 sm:grid-cols-3'>
        {footerLinks.map(({ section, links }) => (
          <div key={`footer_${section}`} className='jutstify-center flex flex-col items-start gap-2'>
            <Typography className='mb-2 pr-4 text-sm font-semibold tracking-tight' as='h2'>
              {section}
            </Typography>

            {links.map(({ href, label }) => (
              <Link
                key={`footer_link_${href}_${label}`}
                href={href}
                className='text-secondary-foreground hover:text-accent-foreground text-sm'
              >
                {label}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </nav>
  );
};

'use client';

import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { FC } from 'react';
import { Button, Typography } from 'ui-lib';

export type GenericErrorProps = {
  errorType: string;
  errorDescription: string;
  message: string;
};

export const GenericError: FC<GenericErrorProps> = ({ errorType, errorDescription, message }) => {
  const router = useRouter();

  return (
    <div className='p-6'>
      <Typography variant='h2'>{errorType}</Typography>
      <Typography variant='lead'>{errorDescription}</Typography>

      <p className='pt-6'>Error Message:</p>
      <pre className='p-2'>{message}</pre>
      <p className='pt-4'>
        Please&nbsp;
        <Link href='https://www.matterhive.co.uk/contact' className='hover:text-primary underline'>
          contact support
        </Link>
      </p>
      <Button
        variant='link'
        className='pl-0 pt-6'
        onClick={() => {
          router.back();
        }}
      >
        Back
      </Button>
    </div>
  );
};

import { Currency } from '@/common';
import { FC } from 'react';
import { FormControl, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'ui-lib';

export type CurrencySelectProps = {
  currencies: Currency[];
  onChange: (...event: any[]) => void;
  value: string;
};

export const CurrencySelect: FC<CurrencySelectProps> = ({ currencies, onChange, value }) => {
  return (
    <Select onValueChange={onChange} defaultValue={value}>
      <FormControl>
        <SelectTrigger>
          <SelectValue placeholder='Select a currency' />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        {currencies.map((currency) => (
          <SelectItem key={`currency_select_${currency.id}`} value={currency.id}>
            {currency.name} ({currency.symbolNative})
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

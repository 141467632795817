'use client';

import { createOrganisation } from '@/actions';
import { OrganisationPost, organisationsOrganisationIdPermissionsTag, organisationsTag, toastTimeout } from '@/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSWRConfig } from 'swr';
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Typography,
  toast,
} from 'ui-lib';
import { z } from 'zod';

const createOrganisationFormSchema = z.object({
  organisationName: z
    .string()
    .min(2, {
      message: 'Name must be at least 2 characters.',
    })
    .max(200, {
      message: 'Name must not be longer than 200 characters.',
    }),
  sraLicenseNumber: z
    .string()
    .max(60, {
      message: 'SRA Number must not be longer than 60 characters.',
    })
    .optional(),
  clcLicenseNumber: z
    .string()
    .max(60, {
      message: 'CLC Number must not be longer than 60 characters.',
    })
    .optional(),
  line1: z
    .string()
    .min(2, { message: 'Address line 1 must be at least 2 characters' })
    .max(200, 'Address line 1 must not be longer than 200 characters.'),
  line2: z.string().max(200, 'Address line 2 must not be longer than 200 characters.').optional(),
  line3: z.string().max(200, 'Address line 3 must not be longer than 200 characters.').optional(),
  city: z
    .string()
    .min(2, { message: 'City / Town must be at least 2 characters' })
    .max(200, 'City / Town must not be longer than 200 characters.'),
  county: z.string().max(200, 'County / Province must not be longer than 200 characters.').optional(),
  postcode: z
    .string()
    .min(2, 'Postcode must be at least 2 characters')
    .max(20, 'Postcode must not be longer than 200 characters.'),
});

type CreateOrganisationFormValues = z.infer<typeof createOrganisationFormSchema>;

export const CreateOrganisationForm: FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate } = useSWRConfig();

  const onSubmit = async (data: CreateOrganisationFormValues) => {
    setIsLoading(true);

    const newOrganisation: OrganisationPost = {
      name: data.organisationName,
      sraLicenseNumber: data.sraLicenseNumber,
      clcLicenseNumber: data.clcLicenseNumber,
      address: {
        line1: data.line1,
        line2: data.line2,
        line3: data.line3,
        city: data.city,
        county: data.county,
        postcode: data.postcode,
      },
    };

    try {
      const res = await createOrganisation(newOrganisation);

      if (res?.error) {
        throw res.error;
      }

      toast.success(`Organisation Created`, {
        description: `${data.organisationName} created`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });

      await mutate(organisationsTag());
      await mutate(organisationsOrganisationIdPermissionsTag({ pathParams: { organisationId: res?.data?.id ?? '' } }));
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
  };

  const defaultValues: Partial<CreateOrganisationFormValues> = {
    organisationName: '',
    sraLicenseNumber: '',
    clcLicenseNumber: '',
    line1: '',
    line2: '',
    line3: '',
    city: '',
    county: '',
    postcode: '',
  };

  const form = useForm<CreateOrganisationFormValues>({
    resolver: zodResolver(createOrganisationFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  return (
    <div className='container pt-6'>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
          <Typography variant='h3'>Organisation Details</Typography>

          <FormField
            control={form.control}
            name='organisationName'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Organisation Name</FormLabel>
                <FormControl>
                  <Input placeholder='The name of your organisation' {...field} />
                </FormControl>
                <FormDescription>The name of your organisation.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='sraLicenseNumber'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  SRA Number{' '}
                  <Typography variant='mutedText' as='span'>
                    (optional)
                  </Typography>
                </FormLabel>
                <FormControl>
                  <Input placeholder='SRA Number' {...field} />
                </FormControl>
                <FormDescription>Your organisations SRA Number</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='clcLicenseNumber'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  CLC Number{' '}
                  <Typography variant='mutedText' as='span'>
                    (optional)
                  </Typography>
                </FormLabel>
                <FormControl>
                  <Input placeholder='CLC Number' {...field} />
                </FormControl>
                <FormDescription>Your organisations CLC Number</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <Typography variant='h3'>Registered Office Address</Typography>

          <FormField
            control={form.control}
            name='line1'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Line 1</FormLabel>
                <FormControl>
                  <Input placeholder='Address line 1' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='line2'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Line 2
                  <Typography variant='mutedText' as='span'>
                    (optional)
                  </Typography>
                </FormLabel>
                <FormControl>
                  <Input placeholder='Address line 2' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='line3'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Line 3
                  <Typography variant='mutedText' as='span'>
                    (optional)
                  </Typography>
                </FormLabel>
                <FormControl>
                  <Input placeholder='Address line 3' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='city'
            render={({ field }) => (
              <FormItem>
                <FormLabel>City / Town</FormLabel>
                <FormControl>
                  <Input placeholder='City / Town' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='county'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  County / Province
                  <Typography variant='mutedText' as='span'>
                    (optional)
                  </Typography>
                </FormLabel>
                <FormControl>
                  <Input placeholder='County / Province' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='postcode'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Postcode</FormLabel>
                <FormControl>
                  <Input placeholder='Postcode' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className='flex flex-row justify-end'>
            <Button type='submit' disabled={isLoading}>
              Create Organisation
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

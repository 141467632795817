'use client';

import { Currency, MatterPost, Organisation, mattersTag, toastTimeout } from '@/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Typography,
  toast,
} from 'ui-lib';
import { z } from 'zod';
import { CurrencySelect, OrganisationSelect } from '../Form';
import { useRouter } from 'next/navigation';
import { useSWRConfig } from 'swr';
import { createMatter } from '@/actions';

const createMatterFormSchema = z.object({
  matterName: z
    .string()
    .min(2, { message: 'Matter name must be at least 2 characters' })
    .max(200, { message: 'Matter name must not be longer than 200 characters' }),
  ownerOrganisationId: z.string(),
  price: z.coerce.number().min(0, { message: 'Price must be greated than 0' }),
  currencyId: z.string(),
  line1: z
    .string()
    .min(2, { message: 'Address line 1 must be at least 2 characters' })
    .max(200, 'Address line 1 must not be longer than 200 characters.'),
  line2: z.string().max(200, 'Address line 2 must not be longer than 200 characters.').optional(),
  line3: z.string().max(200, 'Address line 3 must not be longer than 200 characters.').optional(),
  city: z
    .string()
    .min(2, { message: 'City / Town must be at least 2 characters' })
    .max(200, 'City / Town must not be longer than 200 characters.'),
  county: z.string().max(200, 'County / Province must not be longer than 200 characters.').optional(),
  postcode: z
    .string()
    .min(2, 'Postcode must be at least 2 characters')
    .max(20, 'Postcode must not be longer than 200 characters.'),
});

type CreateMatterFormValues = z.infer<typeof createMatterFormSchema>;

interface CreateMatterFormProps {
  currencies: Currency[];
  organisations: Organisation[];
}

export const CreateMatterForm: FC<CreateMatterFormProps> = ({ currencies, organisations }): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate } = useSWRConfig();

  const router = useRouter();

  const onSubmit = async (data: CreateMatterFormValues) => {
    setIsLoading(true);

    const newMatter: MatterPost = {
      name: data.matterName,
      ownerOrganisationId: data.ownerOrganisationId,
      matterDetail: {
        propertyInfo: {
          price: data.price,
          currencyId: data.currencyId,
          address: {
            line1: data.line1,
            line2: data.line2,
            line3: data.line3,
            city: data.city,
            county: data.county,
            postcode: data.postcode,
          },
        },
      },
    };

    try {
      const res = await createMatter(newMatter);

      if (res?.error) {
        throw res.error;
      }

      mutate(mattersTag());
      router.refresh();

      toast.success(`New Matter Created`, {
        description: `${data.matterName} created`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      console.trace(e);
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
  };

  const defaultValues: Partial<CreateMatterFormValues> = {
    ownerOrganisationId: organisations.length === 1 ? organisations[0].id : '',
    price: 0,
    currencyId: currencies.find(({ code }) => code === 'GBP')?.id ?? '',
    line1: '',
    line2: '',
    line3: '',
    city: '',
    county: '',
    postcode: '',
  };

  const form = useForm<CreateMatterFormValues>({
    resolver: zodResolver(createMatterFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  return (
    <div className='container pt-6'>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
          <Typography variant='h3'>Matter Details</Typography>

          <FormField
            control={form.control}
            name='matterName'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Matter Name</FormLabel>
                <FormControl>
                  <Input placeholder='The name of the matter' {...field} />
                </FormControl>
                <FormDescription>A recognisable name for the matter</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='ownerOrganisationId'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Owner Organisation</FormLabel>
                <OrganisationSelect organisations={organisations} onChange={field.onChange} value={field.value} />
                <FormDescription>The organisation that owns this matter</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <Typography variant='h3'>Property Information</Typography>

          <FormField
            control={form.control}
            name='price'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Price</FormLabel>
                <FormControl>
                  <Input placeholder='0' {...field} />
                </FormControl>
                <FormDescription>Sale price of the property</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='currencyId'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Currency</FormLabel>
                <CurrencySelect currencies={currencies} onChange={field.onChange} value={field.value} />
                <FormDescription>Property price currency</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <Typography variant='h4'>Property Address</Typography>
          <FormField
            control={form.control}
            name='line1'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Line 1</FormLabel>
                <FormControl>
                  <Input placeholder='Address line 1' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='line2'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Line 2
                  <Typography variant='mutedText' as='span'>
                    (optional)
                  </Typography>
                </FormLabel>
                <FormControl>
                  <Input placeholder='Address line 2' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='line3'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Line 3
                  <Typography variant='mutedText' as='span'>
                    (optional)
                  </Typography>
                </FormLabel>
                <FormControl>
                  <Input placeholder='Address line 3' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='city'
            render={({ field }) => (
              <FormItem>
                <FormLabel>City / Town</FormLabel>
                <FormControl>
                  <Input placeholder='City / Town' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='county'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  County / Province
                  <Typography variant='mutedText' as='span'>
                    (optional)
                  </Typography>
                </FormLabel>
                <FormControl>
                  <Input placeholder='County / Province' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='postcode'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Postcode</FormLabel>
                <FormControl>
                  <Input placeholder='Postcode' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className='flex flex-row justify-end'>
            <Button type='submit' disabled={isLoading}>
              Create Matter
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

'use client';

import { acceptInviteRequest, rejectInviteRequest } from '@/actions';
import { UserProfile, getUserFullName, toastTimeout } from '@/common';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import { Button, Card, toast } from 'ui-lib';
import { UserAvatar } from '../UserAvatar';

interface MatterInviteRequestCardProps {
  inviteRequestId: string;
  canManageInvites: boolean;
  userProfile?: UserProfile;
}

export const MatterInviteRequestCard: FC<MatterInviteRequestCardProps> = ({
  inviteRequestId,
  canManageInvites,
  userProfile,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const router = useRouter();

  if (!userProfile) {
    return null;
  }

  const onRejectInviteRequest = async (inviteId: string) => {
    setIsLoading(true);

    try {
      const res = await rejectInviteRequest(inviteId);

      if (res?.error) {
        throw res.error;
      }

      toast.info(`Invite request rejected`, {
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
    router.refresh();
  };

  const onAcceptInviteRequest = async (inviteId: string) => {
    setIsLoading(true);

    try {
      const res = await acceptInviteRequest(inviteId);

      if (res?.error) {
        throw res.error;
      }

      toast.info(`Invite request accepted`, {
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
    router.refresh();
  };

  const fullName = getUserFullName(userProfile.user);

  return (
    <Card className='grid grid-cols-1 p-4 sm:flex sm:flex-row sm:items-center sm:justify-between sm:space-x-6'>
      <div className='flex items-center justify-start'>
        <UserAvatar userProfile={userProfile} />
        <div className='flex flex-col pl-6'>
          <p className='text-sm font-medium leading-none'>{fullName}</p>
          <p className='text-muted-foreground text-sm'>{userProfile.user?.email}</p>
        </div>
      </div>
      <div className='flex flex-row justify-between space-x-6 pt-4 sm:justify-end sm:pt-0'>
        <Button
          variant={'outlineDestructive'}
          disabled={!canManageInvites || isLoading}
          onClick={() => {
            onRejectInviteRequest(inviteRequestId);
          }}
        >
          Reject
        </Button>
        <Button
          variant={'default'}
          disabled={!canManageInvites || isLoading}
          onClick={() => {
            onAcceptInviteRequest(inviteRequestId);
          }}
        >
          Accept
        </Button>
      </div>
    </Card>
  );
};

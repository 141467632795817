'use client';

import { useNotificationsGet } from '@/common/gen/api-hooks';
import Link from 'next/link';
import { useContext, useRef } from 'react';
import {
  ActivityLogIcon,
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  BellIcon,
  Button,
  Card,
  CheckIcon,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  toast,
} from 'ui-lib';
import { UserProfileContext } from '../../UserProfileContext';
import { NotificationList } from './NotificationList';
import { NotificationSortByEnum, SortDirectionEnum, notificationsTag, toastTimeout } from '@/common';
import { dismissAllNotifications } from '@/actions';
import { useSWRConfig } from 'swr';

export function NotificationsDropdown() {
  const userProfile = useContext(UserProfileContext);
  const { mutate } = useSWRConfig();

  const {
    data: notifications,
    isLoading,
    isError,
    error,
  } = useNotificationsGet(
    {
      queryParams: {
        sortBy: NotificationSortByEnum.Createdat,
        sortDirection: SortDirectionEnum.Desc,
        limit: 5,
      },
    },
    {},
    {
      refreshInterval: 60 * 1000, // refresh every x milliseconds
    },
  );

  const popoverFocusRef = useRef<any>(null);

  const onDismissAll = async () => {
    try {
      const res = await dismissAllNotifications(userProfile?.userId ?? '');

      if (res?.error) {
        throw res.error;
      }

      toast.success(`Sign up success`, {
        description: `${res.data.affected ?? 0} notifications dismissed`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    mutate(notificationsTag());
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button data-tour-id='navbar-notifications' variant='ghost' size='icon'>
          <BellIcon className='text-secondary-foreground h-6 w-6' />
        </Button>
      </PopoverTrigger>
      {notifications?.meta?.total ? (
        <Badge className='absolute -mt-6 rounded-full p-0 px-1 text-[0.625rem]'>
          <span>{notifications?.meta?.total}</span>
        </Badge>
      ) : null}
      <PopoverContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
          popoverFocusRef.current?.focus();
        }}
        align='center'
        className='xs:w-96 w-72 pr-8'
      >
        <div className='grid gap-4'>
          <div className='space-y-2'>
            <h4 className='font-medium leading-none'>Notifications</h4>
          </div>
          {isLoading ? (
            <Skeleton>
              <Card className='h-16'></Card>
            </Skeleton>
          ) : null}

          {isError ? (
            <Alert>
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{JSON.stringify(error, null, 2)}</AlertDescription>
            </Alert>
          ) : null}

          {!isLoading && !isError ? (
            <NotificationList notifications={notifications?.data ?? []} popoverFocusRef={popoverFocusRef} />
          ) : null}
        </div>

        <Button
          variant='outline'
          className='mt-4 w-full'
          ref={notifications?.data?.length === 0 ? popoverFocusRef : null}
          asChild
        >
          <Link href={`/dashboard/users/${userProfile?.userId}/notifications`}>
            <ActivityLogIcon className='mr-2 h-4 w-4' />
            <span className='min-w-32 text-center'>View All ({notifications?.meta?.total})</span>
          </Link>
        </Button>

        <PopoverClose asChild>
          <Button
            className='mt-2 w-full'
            disabled={isLoading || notifications?.meta?.total === 0}
            onClick={onDismissAll}
          >
            <CheckIcon className='mr-2 h-4 w-4' /> <span className='min-w-32 text-center'>Dismiss all</span>
          </Button>
        </PopoverClose>
      </PopoverContent>
    </Popover>
  );
}

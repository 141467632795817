'use client';

import { createInvite } from '@/actions';
import { InviteResourceEnum, toastTimeout } from '@/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  toast,
} from 'ui-lib';
import { z } from 'zod';

export interface MatterUserInviteCardProps {
  matterId: string;
}

const inviteFormSchema = z.object({
  email: z
    .string()
    .email()
    .min(2, {
      message: 'Email must be at least 2 characters.',
    })
    .max(100, {
      message: 'Email must not be longer than 100 characters.',
    }),
});

type InviteFormValues = z.infer<typeof inviteFormSchema>;

export function MatterUserInviteCard({ matterId }) {
  const [isLoading, setIsLoading] = useState<boolean>();

  const defaultValues: Partial<InviteFormValues> = {
    email: '',
  };

  const form = useForm<InviteFormValues>({
    resolver: zodResolver(inviteFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  const onSubmit = async (data: InviteFormValues) => {
    setIsLoading(true);

    try {
      const res = await createInvite({
        email: data.email,
        inviteResource: InviteResourceEnum.Matter,
        invitedToId: matterId,
      });

      if (res?.error) {
        throw res.error;
      }

      form.reset();

      toast.success(`User Invited`, {
        description: `${data.email} invited`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
  };

  return (
    <Card className=' lg:col-span-3'>
      <CardHeader>
        <CardTitle>Invite Member</CardTitle>
        <CardDescription>Invite a user to be a member of your matter</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder='Email of the user to invite' {...field} />
                  </FormControl>
                  <FormDescription>The invite will be sent to this email address.</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className='flex flex-row justify-end'>
              <Button type='submit' disabled={isLoading}>
                Invite
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}

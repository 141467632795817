'use client';

import { UserProfile } from '@/common';
import { FC, createContext } from 'react';

interface UserProfileProviderProps {
  children?: React.ReactNode;
  userProfile: UserProfile | null;
}

export const UserProfileContext = createContext<UserProfile | null>(null);

export const UserProfileContextProvider: FC<UserProfileProviderProps> = ({ children, userProfile }) => {
  return <UserProfileContext.Provider value={userProfile}>{children}</UserProfileContext.Provider>;
};

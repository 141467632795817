import { Organisation } from '@/common';
import { FC } from 'react';
import { FormControl, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'ui-lib';

export type OrganisationSelectProps = {
  organisations: Organisation[];
  onChange: (...event: any[]) => void;
  value: string;
};

export const OrganisationSelect: FC<OrganisationSelectProps> = ({ organisations, onChange, value }) => {
  return (
    <Select onValueChange={onChange} defaultValue={value}>
      <FormControl>
        <SelectTrigger>
          <SelectValue placeholder='Select a organisation' />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        {organisations.map((organisation) => (
          <SelectItem key={`organisation_select_${organisation.id}`} value={organisation.id ?? ''}>
            {organisation.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

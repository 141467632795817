import { InviteMeta, Matter, MatterPermissionsEnum } from '@/common';
import { FC } from 'react';
import { Alert, AlertDescription, AlertTitle, InfoCircledIcon, Typography } from 'ui-lib';
import { MatterInviteCard } from './MatterInviteCard';
import { MatterUserInviteCard } from './MatterUserInviteCard';

export interface MatterInvitesProps {
  matter: Matter;
  matterId: string;
  matterInvites: InviteMeta[];
  matterPermissions: MatterPermissionsEnum[];
  tourId?: string;
}

export const MatterInvites: FC<MatterInvitesProps> = ({
  matter,
  matterId,
  matterInvites,
  matterPermissions,
  tourId,
}) => {
  const canManageInvites = matterPermissions.includes(MatterPermissionsEnum.CanManageInvites) ?? false;

  const matterIsOpen = !matter.closedAt;

  const noPendingInvites = (matterInvites.length ?? 0) === 0;

  const noInvitesMessage =
    canManageInvites && matterIsOpen
      ? 'You can invite users to your matter below.'
      : 'Your matter has no pending invitations.';

  return (
    <>
      <Typography data-tour-id={tourId} as='h3' variant='h3' className='pt-6'>
        Invited Members
      </Typography>
      <Typography variant='lead' as='p' className='text-lg'>
        Users who have been invited to this matter
      </Typography>
      <div className='flex flex-col gap-4 py-6'>
        {noPendingInvites ? (
          <Alert>
            <InfoCircledIcon className='h-4 w-4' />
            <AlertTitle>No Pending Invites</AlertTitle>
            <AlertDescription>{noInvitesMessage}</AlertDescription>
          </Alert>
        ) : null}
        {matterInvites.map((invite) => (
          <MatterInviteCard
            key={`invite_card_${invite.invite.id}`}
            inviteId={invite.invite.id ?? ''}
            user={invite.invitedUser}
            canManageInvites={canManageInvites && matterIsOpen}
          />
        ))}
        {canManageInvites && matterIsOpen ? <MatterUserInviteCard matterId={matterId} /> : null}
      </div>
    </>
  );
};

import commandScore from 'command-score';

// TODO: Swap commandScore for another fuzzy search lib with better TS support
// commandScore is what's used by radix, so kept the same for now
export const comboboxLabelFilter = <T extends { label: string }>(
  items: T[],
  key: keyof T,
): ((_value: string, _search: string) => number) => {
  const filter = (value: string, search: string) => {
    const item = items.find((item) => value === item[key]);

    if (!item) {
      return 0;
    }

    return commandScore(item.label, search);
  };

  return filter;
};

import { Route } from 'next';
import Link from 'next/link';
import { FC } from 'react';

export type InvitedToProps = {
  name: string;
  href?: Route<string>;
};

export const InvitedTo: FC<InvitedToProps> = ({ name, href }) => {
  if (!name) {
    return null;
  }

  if (!href) {
    return <span className='font-bold'>{name}</span>;
  }

  return (
    <span>
      <Link href={href} className='font-bold hover:underline'>
        {name}
      </Link>
    </span>
  );
};

'use client';

import { ChangeEvent, FC, useRef } from 'react';
import { Input, InputProps } from 'ui-lib';

type SearchClientProps = InputProps & {
  onSearch: (search: string) => void;
};

export const SearchClient: FC<SearchClientProps> = ({ onSearch, ...props }) => {
  const throttleDelay = 500;

  const timeoutRef = useRef<NodeJS.Timeout | null>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        onSearch(event.target.value);

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = null;
      }, throttleDelay);
    }
  };

  return <Input type='text' defaultValue={''} placeholder='Search...' onChange={handleChange} {...props} />;
};

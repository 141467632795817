'use client';

import { Matter, MatterPermissionsEnum, OrganisationPermissionsEnum } from '@/common';
import {
  useMattersGet,
  useMattersMatterIdGet,
  useMattersMatterIdPermissionsGet,
  useOrganisationsGet,
} from '@/common/gen/api-hooks';
import { PlatformNavLinks } from '@/components/Navbar';
import type { Route } from 'next';
import { useParams } from 'next/navigation';
import {
  ChatBubbleIcon,
  FileTextIcon,
  HomeIcon,
  IdCardIcon,
  MixerHorizontalIcon,
  Pencil2Icon,
  PersonIcon,
  PieChartIcon,
  PlusIcon,
  Separator,
} from 'ui-lib';
import { SidebarSection, SidebarSectionLinks } from '../SidebarSectionLinks';
import { MatterCombobox } from './MatterCombobox';

const getMattersSidebarSections = (canCreate: boolean): SidebarSection[] => {
  const mattersSection: SidebarSection = {
    section: 'Matters',
    links: [
      {
        href: `/dashboard/matters` satisfies Route<`/dashboard/matters`>,
        label: 'My Matters',
        icon: <HomeIcon />,
      },
    ],
  };

  if (canCreate) {
    mattersSection.links.push({
      href: `/dashboard/matters/create` satisfies Route<`/dashboard/matters/create`>,
      label: 'New Matter',
      icon: <PlusIcon />,
      tourId: 'matters-create-new-matter',
    });
  }

  return [mattersSection];
};

const getMatterSidebarSections = (
  selectedMatterId: string = '',
  matterPermissions: Set<MatterPermissionsEnum>,
  selectedMatter?: Matter,
): SidebarSection[] => {
  if (!selectedMatter || !matterPermissions) {
    return [];
  }

  const matterSection: SidebarSection = {
    section: '',
    links: [
      {
        href: `/dashboard/matters/${selectedMatterId}` satisfies Route<`/dashboard/matters/${string}`>,
        label: 'Overview',
        icon: <HomeIcon />,
        tourId: 'matters-sidebar-overview',
      },
      {
        href: `/dashboard/matters/${selectedMatterId}/details` satisfies Route<`/dashboard/matters/${string}/details`>,
        label: 'Details',
        icon: <IdCardIcon />,
        tourId: 'matters-sidebar-details',
      },
      ...(matterPermissions.has(MatterPermissionsEnum.CanEdit) && !selectedMatter.closedAt
        ? [
            {
              href: `/dashboard/matters/${selectedMatterId}/manage-details` satisfies Route<`/dashboard/matters/${string}/manage-details`>,
              label: 'Manage Details',
              icon: <Pencil2Icon />,
              tourId: 'matters-sidebar-manage-details',
            },
          ]
        : []),
      {
        href: `/dashboard/matters/${selectedMatterId}/progress` satisfies Route<`/dashboard/matters/${string}/progress`>,
        label: 'Progress',
        icon: <PieChartIcon />,
        tourId: 'matters-sidebar-progress',
      },
      {
        href: `/dashboard/matters/${selectedMatterId}/documents` satisfies Route<`/dashboard/matters/${string}/documents`>,
        label: 'Documents',
        icon: <FileTextIcon />,
        tourId: 'matters-sidebar-documents',
      },
      {
        href: `/dashboard/matters/${selectedMatterId}/enquiries` satisfies Route<`/dashboard/matters/${string}/enquiries`>,
        label: 'Enquiries',
        icon: <ChatBubbleIcon />,
        tourId: 'matters-sidebar-enquiries',
      },
      {
        href: `/dashboard/matters/${selectedMatterId}/members` satisfies Route<`/dashboard/matters/${string}/members`>,
        label: 'Members',
        icon: <PersonIcon />,
        tourId: 'matters-sidebar-members',
      },
      {
        href: `/dashboard/matters/${selectedMatterId}/settings` satisfies Route<`/dashboard/matters/${string}/settings`>,
        label: 'Settings',
        icon: <MixerHorizontalIcon />,
        tourId: 'matters-sidebar-settings',
      },
    ],
  };

  return [matterSection];
};

export const MatterSidebarContent = (): JSX.Element => {
  const params = useParams<{ matterId: string }>();
  const matterId = params?.matterId ?? '';

  const { data: matters } = useMattersGet({ queryParams: { limit: 0 } });

  const { data: paramMatter } = useMattersMatterIdGet({
    pathParams: {
      matterId: params.matterId,
    },
  });

  const { data: matterPermissions } = useMattersMatterIdPermissionsGet({
    pathParams: { matterId },
  });

  const { data: organisationsWithCanCreatePermission } = useOrganisationsGet({
    queryParams: { limit: 0, organisationPermission: OrganisationPermissionsEnum.CanCreateMatter },
  });

  const canCreate = (organisationsWithCanCreatePermission?.meta?.total ?? 0) > 0 ?? false;

  const matterPermissionsSet = new Set<MatterPermissionsEnum>(matterPermissions?.roles ?? []);

  let selectedMatter = matters?.data?.find(({ id }) => id === matterId);

  if (!selectedMatter && paramMatter) {
    selectedMatter = paramMatter;
    matters?.data?.unshift(paramMatter);
  }

  const mattersSidebarSections = getMattersSidebarSections(canCreate);

  const matterSidebarSections = getMatterSidebarSections(matterId, matterPermissionsSet, selectedMatter);

  return (
    <div className={'pb-12 pr-4'}>
      <div className='pt-6 md:hidden md:py-0'>
        <h2 className='mb-2 pr-4 text-lg font-semibold tracking-tight'>Navigation</h2>
        <div className='ml-2'>
          <PlatformNavLinks />
        </div>
        <Separator className='mt-6' />
      </div>

      <div className='space-y-4 sm:py-6'>
        <SidebarSectionLinks sidebarSections={mattersSidebarSections} />

        <Separator className='mt-6' />
        <div className='pt-2'>
          <MatterCombobox matterData={matters?.data ?? []} />
        </div>

        <SidebarSectionLinks sidebarSections={matterSidebarSections} />
      </div>
    </div>
  );
};

'use client';

import {
  Enquiry,
  UserProfile,
  adjustUTCDateToLocalTimezone,
  getFormattedDateAndTimeString,
  getFormattedDateString,
  getRelativeTimeString,
  getUserFullName,
} from '@/common';
import Link from 'next/link';
import { FC } from 'react';
import { Badge, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, Typography, cn } from 'ui-lib';
import { UserAvatar } from '../UserAvatar';
import { getEnquiryBadgeVariant, getEnquiryStatusText } from './utils';

interface EnquiryOverviewHeaderProps {
  enquiry: Enquiry;
  createdByUserProfile: UserProfile;
  lastActivityAt?: Date;
  className?: string;
  children?: React.ReactNode;
}

export const EnquiryOverviewHeader: FC<EnquiryOverviewHeaderProps> = ({
  enquiry,
  createdByUserProfile,
  lastActivityAt,
  className,
  children,
}) => {
  const statusText = getEnquiryStatusText(enquiry);
  const badgeVariant = getEnquiryBadgeVariant(enquiry);

  const fullName = getUserFullName(createdByUserProfile?.user);

  const adjustedEnquiryDate = adjustUTCDateToLocalTimezone(new Date(enquiry.createdAt as unknown as string));

  const formattedEnquiryDate = getFormattedDateString(adjustedEnquiryDate);
  const formattedEnquiryDateTime = getFormattedDateAndTimeString(adjustedEnquiryDate);

  const relativeEnquiryDate = getRelativeTimeString(adjustedEnquiryDate);

  const adjustedLastActivity = lastActivityAt ? adjustUTCDateToLocalTimezone(new Date(lastActivityAt)) : null;
  const relativeLastActivityString = adjustedLastActivity ? getRelativeTimeString(adjustedLastActivity) : null;
  const lastActivityDateTimeString = adjustedLastActivity ? getFormattedDateAndTimeString(adjustedLastActivity) : null;

  return (
    <div
      className={cn(
        'xs:flex-row xs:items-center flex flex-col-reverse items-start justify-between gap-4 space-x-2 pb-2',
        className,
      )}
    >
      <div className={cn('flex flex-row items-center justify-start space-x-2')}>
        <UserAvatar className='h-10 w-10' userProfile={createdByUserProfile} />
        <div className='flex flex-col items-start justify-evenly'>
          <Link href={`/dashboard/users/${enquiry.createdById}`}>
            <Typography variant='smallText'>{fullName}</Typography>
          </Link>
          <Typography variant='mutedText' className='text-xs'>
            {formattedEnquiryDate}
          </Typography>
        </div>
      </div>
      <div className={cn('flex flex-col items-end justify-start')}>
        <Badge className='flex w-full items-center justify-center text-sm' variant={badgeVariant}>
          {statusText}
        </Badge>
        <div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Typography variant='mutedText' className='text-xs'>
                  Opened: {relativeEnquiryDate}
                </Typography>
              </TooltipTrigger>
              <TooltipContent>{formattedEnquiryDateTime}</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <br />

          {adjustedLastActivity ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Typography variant='mutedText' className='text-xs'>
                    Last activity: {relativeLastActivityString}
                  </Typography>
                </TooltipTrigger>
                <TooltipContent>{lastActivityDateTimeString}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null}
        </div>
        {children}
      </div>
    </div>
  );
};

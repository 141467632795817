import { InviteRequestMeta } from '@/common';
import { FC } from 'react';
import { Alert, AlertDescription, AlertTitle, InfoCircledIcon, Typography } from 'ui-lib';
import { OrganisationInviteRequestCard } from './OrganisationInviteRequestCard';

export interface OrganisationInviteRequestsProps {
  organisationId: string;
  organisationInviteRequests: InviteRequestMeta[];
  canManageInviteRequests: boolean;
  tourId?: string;
}

export const OrganisationInviteRequests: FC<OrganisationInviteRequestsProps> = ({
  organisationInviteRequests,
  canManageInviteRequests,
  tourId,
}) => {
  const noPendingInviteRequests = (organisationInviteRequests.length ?? 0) === 0;

  const noInviteRequestsMessage = 'Your organisation has no pending invite requests.';

  return (
    <>
      <Typography data-tour-id={tourId} as='h3' variant='h3' className='pt-12'>
        Invite Requests
      </Typography>
      <Typography variant='lead' as='p' className='text-lg'>
        Users who have requested access to this organisation
      </Typography>
      <div className='flex flex-col gap-4 py-6'>
        {noPendingInviteRequests ? (
          <Alert>
            <InfoCircledIcon className='h-4 w-4' />
            <AlertTitle>No Pending Invite Requests</AlertTitle>
            <AlertDescription>{noInviteRequestsMessage}</AlertDescription>
          </Alert>
        ) : null}
        {organisationInviteRequests.map((inviteRequest) => (
          <OrganisationInviteRequestCard
            key={`inviteRequest_card_${inviteRequest.inviteRequest.id}`}
            inviteRequestId={inviteRequest.inviteRequest.id ?? ''}
            userProfile={inviteRequest.requestedByUserProfile}
            canManageInvites={canManageInviteRequests}
          />
        ))}
      </div>
    </>
  );
};

'use client';

import { OrganisationProfile as OrganisationProfileData, OrganisationPermissionsEnum } from '@/common';
import { FC } from 'react';
import { OrganisationProfileCard } from './OrganisationProfileCard';
import { useOrganisationsOrganisationIdPermissionsGet } from '@/common/gen/api-hooks';

interface OrganisationProfileProps {
  organisationProfile: OrganisationProfileData;
  tourId?: string;
}

export const OrganisationProfile: FC<OrganisationProfileProps> = ({ organisationProfile, tourId }) => {
  const { data: organisationPermissions } = useOrganisationsOrganisationIdPermissionsGet({
    pathParams: { organisationId: organisationProfile?.organisationId ?? '' },
  });

  const canEdit = organisationPermissions?.roles?.some((role) => role === OrganisationPermissionsEnum.CanEdit) ?? false;

  return (
    <div className='grid w-full grid-cols-2 pt-6 lg:grid-cols-3'>
      <OrganisationProfileCard tourId={tourId} organisationProfile={organisationProfile} canEdit={canEdit} />
    </div>
  );
};

import { Currency } from '@/common';
import { FC } from 'react';
import { cn } from 'ui-lib';

export type PriceProps = {
  price?: number;
  currency?: Currency;
} & React.HTMLAttributes<HTMLSpanElement>;

export const Price: FC<PriceProps> = ({ price, currency, className }) => {
  const formattedPrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency: currency?.code ?? '' }).format(
    price ?? 0,
  );

  return <span className={cn(className)}>{formattedPrice}</span>;
};

import { UserProfile, getUserFullName } from '@/common';
import Link from 'next/link';
import { FC } from 'react';

export type InvitedByProps = {
  userProfile?: UserProfile;
};

export const InvitedBy: FC<InvitedByProps> = ({ userProfile }) => {
  if (!userProfile) {
    return null;
  }

  const userId = userProfile.userId;

  const fullName = getUserFullName(userProfile.user);

  return (
    <span>
      <Link href={`/dashboard/users/${userId}`} className='font-bold hover:underline'>
        {fullName} &nbsp;
      </Link>
    </span>
  );
};

'use client';

import {
  Document,
  UserProfile,
  adjustUTCDateToLocalTimezone,
  getFormattedDateAndTimeString,
  getFormattedDateString,
  getRelativeTimeString,
  getUserFullName,
} from '@/common';
import Link from 'next/link';
import { FC } from 'react';
import { Badge, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, Typography, cn } from 'ui-lib';
import { UserAvatar } from '../UserAvatar';

interface DocumentOverviewHeaderProps {
  document: Document;
  createdByUserProfile: UserProfile;
  lastActivityAt?: Date;
  className?: string;
  children?: React.ReactNode;
}

const getContentTypeText = (contentType?: string): string => {
  if (contentType === 'application/pdf') {
    return 'pdf';
  }

  return 'file';
};

export const DocumentOverviewHeader: FC<DocumentOverviewHeaderProps> = ({
  document,
  createdByUserProfile,
  lastActivityAt,
  className,
  children,
}) => {
  const statusText = getContentTypeText(document.contentType);
  const badgeVariant = 'outline';

  const fullName = getUserFullName(createdByUserProfile?.user);

  const adjustedDocumentDate = adjustUTCDateToLocalTimezone(new Date(document.createdAt as unknown as string));

  const formattedDocumentDate = getFormattedDateString(adjustedDocumentDate);
  const formattedDocumentDateTime = getFormattedDateAndTimeString(adjustedDocumentDate);

  const relativeDocumentDate = getRelativeTimeString(adjustedDocumentDate);

  const adjustedLastActivity = lastActivityAt ? adjustUTCDateToLocalTimezone(new Date(lastActivityAt)) : null;
  const relativeLastActivityString = adjustedLastActivity ? getRelativeTimeString(adjustedLastActivity) : null;
  const lastActivityDateTimeString = adjustedLastActivity ? getFormattedDateAndTimeString(adjustedLastActivity) : null;

  return (
    <div
      className={cn(
        'xs:flex-row xs:items-start flex flex-col-reverse items-start justify-between gap-4 space-x-2 pb-2',
        className,
      )}
    >
      <div className='flex flex-col items-start justify-start space-y-2'>
        <div className={cn('flex flex-row items-center justify-start space-x-2')}>
          <UserAvatar className='h-10 w-10' userProfile={createdByUserProfile} />
          <div className='flex flex-col items-start justify-evenly'>
            <Link href={`/dashboard/users/${document.createdById}`}>
              <Typography variant='smallText'>{fullName}</Typography>
            </Link>
            <Typography variant='mutedText' className='text-xs'>
              {formattedDocumentDate}
            </Typography>
          </div>
        </div>
        <Typography variant='largeText'>{document.name}</Typography>
      </div>

      <div className={cn('flex flex-col items-end justify-start')}>
        <Badge className='flex w-full items-center justify-center text-sm' variant={badgeVariant}>
          {statusText}
        </Badge>
        <div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Typography variant='mutedText' className='text-xs'>
                  Uploaded: {relativeDocumentDate}
                </Typography>
              </TooltipTrigger>
              <TooltipContent>{formattedDocumentDateTime}</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <br />

          {adjustedLastActivity ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Typography variant='mutedText' className='text-xs'>
                    Last activity: {relativeLastActivityString}
                  </Typography>
                </TooltipTrigger>
                <TooltipContent>{lastActivityDateTimeString}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null}
        </div>
        {children}
      </div>
    </div>
  );
};

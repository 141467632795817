'use client';

import { updateOrganisation, updateOrganisationProfile } from '@/actions';
import {
  OrganisationPatch,
  OrganisationProfile as OrganisationProfileData,
  OrganisationProfilePatch,
  toastTimeout,
} from '@/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
  Typography,
  toast,
} from 'ui-lib';
import { z } from 'zod';

interface ManageOrganisationProfileProps {
  organisationProfile: OrganisationProfileData;
  canEdit: boolean;
}

const updateOrganisationProfileFormSchema = z.object({
  organisationName: z
    .string()
    .min(2, {
      message: 'Name must be at least 2 characters.',
    })
    .max(200, {
      message: 'Name must not be longer than 200 characters.',
    }),
  sraLicenseNumber: z
    .string()
    .max(60, {
      message: 'SRA Number must not be longer than 60 characters.',
    })
    .optional(),
  clcLicenseNumber: z
    .string()
    .max(60, {
      message: 'CLC Number must not be longer than 60 characters.',
    })
    .optional(),
  line1: z
    .string()
    .min(2, { message: 'Address line 1 must be at least 2 characters' })
    .max(200, 'Address line 1 must not be longer than 200 characters.'),
  line2: z.string().max(200, 'Address line 2 must not be longer than 200 characters.').optional(),
  line3: z.string().max(200, 'Address line 3 must not be longer than 200 characters.').optional(),
  city: z
    .string()
    .min(2, { message: 'City / Town must be at least 2 characters' })
    .max(200, 'City / Town must not be longer than 200 characters.'),
  county: z.string().max(200, 'County / Province must not be longer than 200 characters.').optional(),
  postcode: z
    .string()
    .min(2, 'Postcode must be at least 2 characters')
    .max(20, 'Postcode must not be longer than 200 characters.'),
  bio: z.string().max(400, 'Biography must not be longer than 400 characters.').optional(),
  email: z.string().max(100, 'Email must not be longer than 100 characters.').optional(),
  phone: z.string().max(50, 'Phone must not be longer than 50 characters.').optional(),
  website: z.string().max(200, 'Website must not be longer than 200 characters.').optional(),
});

type UpdateOrganisationFormValues = z.infer<typeof updateOrganisationProfileFormSchema>;

export const ManageOrganisationProfile: FC<ManageOrganisationProfileProps> = ({ organisationProfile, canEdit }) => {
  const [isLoading, setIsLoading] = useState<boolean>();

  const router = useRouter();

  const onSubmit = async (data: UpdateOrganisationFormValues) => {
    setIsLoading(true);

    const updatedOrganisation: OrganisationPatch = {
      name: data.organisationName,
      sraLicenseNumber: data.sraLicenseNumber,
      clcLicenseNumber: data.clcLicenseNumber,
      registeredOffice: {
        line1: data.line1,
        line2: data.line2,
        line3: data.line3,
        city: data.city,
        county: data.county,
        postcode: data.postcode,
      },
    };

    const updatedOrganisationProfile: OrganisationProfilePatch = {
      bio: data.bio,
      email: data.email,
      phone: data.phone,
      website: data.website,
    };

    try {
      const updateOrganisationRes = await updateOrganisation(
        organisationProfile.organisationId ?? '',
        updatedOrganisation,
      );

      if (updateOrganisationRes.error) {
        throw updateOrganisationRes.error;
      }

      const updateOrganisationProfileRes = await updateOrganisationProfile(
        organisationProfile.organisationId ?? '',
        updatedOrganisationProfile,
      );

      if (updateOrganisationProfileRes.error) {
        throw updateOrganisationProfileRes.error;
      }

      router.refresh();

      toast.success(`Organisation Updated`, {
        description: `${data.organisationName} updated`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
  };

  const defaultValues: Partial<UpdateOrganisationFormValues> = {
    organisationName: organisationProfile?.organisation?.name ?? '',
    sraLicenseNumber: organisationProfile?.organisation?.sraLicenseNumber ?? '',
    clcLicenseNumber: organisationProfile?.organisation?.clcLicenseNumber ?? '',
    line1: organisationProfile?.organisation?.registeredOffice?.line1 ?? '',
    line2: organisationProfile?.organisation?.registeredOffice?.line2 ?? '',
    line3: organisationProfile?.organisation?.registeredOffice?.line3 ?? '',
    city: organisationProfile?.organisation?.registeredOffice?.city ?? '',
    county: organisationProfile?.organisation?.registeredOffice?.county ?? '',
    postcode: organisationProfile?.organisation?.registeredOffice?.postcode ?? '',
    bio: organisationProfile?.bio ?? '',
    email: organisationProfile?.email ?? '',
    phone: organisationProfile?.phone ?? '',
    website: organisationProfile?.website ?? '',
  };

  const form = useForm<UpdateOrganisationFormValues>({
    resolver: zodResolver(updateOrganisationProfileFormSchema),
    defaultValues,
    mode: 'onSubmit',
    disabled: !canEdit,
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
        <Typography variant='h3'>Organisation Details</Typography>

        <FormField
          control={form.control}
          name='organisationName'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Organisation Name</FormLabel>
              <FormControl>
                <Input placeholder='The name of your organisation' {...field} />
              </FormControl>
              <FormDescription>The name of your organisation.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='sraLicenseNumber'
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                SRA Number{' '}
                <Typography variant='mutedText' as='span'>
                  (optional)
                </Typography>
              </FormLabel>
              <FormControl>
                <Input placeholder='SRA Number' {...field} />
              </FormControl>
              <FormDescription>Your organisations SRA Number</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='clcLicenseNumber'
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                CLC Number{' '}
                <Typography variant='mutedText' as='span'>
                  (optional)
                </Typography>
              </FormLabel>
              <FormControl>
                <Input placeholder='CLC Number' {...field} />
              </FormControl>
              <FormDescription>Your organisations CLC Number</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Typography variant='h3'>Organisation Bio</Typography>

        <FormField
          control={form.control}
          name='bio'
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Bio{' '}
                <Typography variant='mutedText' as='span'>
                  (optional)
                </Typography>
              </FormLabel>
              <FormControl>
                <Textarea placeholder='Tell us a little bit about yourselves' {...field} />
              </FormControl>
              <FormDescription>A short biography other users will see.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='website'
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Website{' '}
                <Typography variant='mutedText' as='span'>
                  (optional)
                </Typography>
              </FormLabel>
              <FormControl>
                <Input placeholder='Website' {...field} />
              </FormControl>
              <FormDescription>Your organisations website</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='email'
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Email{' '}
                <Typography variant='mutedText' as='span'>
                  (optional)
                </Typography>
              </FormLabel>
              <FormControl>
                <Input placeholder='email address' {...field} />
              </FormControl>
              <FormDescription>Your organisations email address</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='phone'
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Phone Number{' '}
                <Typography variant='mutedText' as='span'>
                  (optional)
                </Typography>
              </FormLabel>
              <FormControl>
                <Input placeholder='Phone Number' {...field} />
              </FormControl>
              <FormDescription>Your organisations Phone Number</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Typography variant='h3'>Registered Office Address</Typography>

        <FormField
          control={form.control}
          name='line1'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Line 1</FormLabel>
              <FormControl>
                <Input placeholder='Address line 1' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='line2'
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Line 2
                <Typography variant='mutedText' as='span'>
                  (optional)
                </Typography>
              </FormLabel>
              <FormControl>
                <Input placeholder='Address line 2' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='line3'
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Line 3
                <Typography variant='mutedText' as='span'>
                  (optional)
                </Typography>
              </FormLabel>
              <FormControl>
                <Input placeholder='Address line 3' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='city'
          render={({ field }) => (
            <FormItem>
              <FormLabel>City / Town</FormLabel>
              <FormControl>
                <Input placeholder='City / Town' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='county'
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                County / Province
                <Typography variant='mutedText' as='span'>
                  (optional)
                </Typography>
              </FormLabel>
              <FormControl>
                <Input placeholder='County / Province' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='postcode'
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Postcode
                <Typography variant='mutedText' as='span'>
                  (optional)
                </Typography>
              </FormLabel>
              <FormControl>
                <Input placeholder='Postcode' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className='flex flex-row justify-end'>
          <Button type='submit' disabled={isLoading || !canEdit}>
            Update profile
          </Button>
        </div>
      </form>
    </Form>
  );
};

import Link from 'next/link';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Input,
  Label,
  Typography,
} from 'ui-lib';

export const NoOrganisations = () => {
  return (
    <div className='w-full'>
      <Typography variant='lead' className='py-6'>
        You are not a member of any organisations.
      </Typography>

      <div className='grid grid-cols-1 gap-6 py-6 lg:grid-cols-7'>
        <Card className='lg:col-span-3'>
          <CardHeader>
            <CardTitle>Request invite</CardTitle>
            <CardDescription>Request an invite to an organisation.</CardDescription>
          </CardHeader>
          <CardContent>
            <form>
              <div className='grid w-full items-center gap-4'>
                <div className='flex flex-col space-y-1.5'>
                  <Label htmlFor='email'>Manager Email</Label>
                  <Input id='email' placeholder='Manager email (todo)' />
                </div>
              </div>
            </form>
          </CardContent>
          <CardFooter className='flex justify-end'>
            <Button>Request</Button>
          </CardFooter>
        </Card>

        <div className='flex h-full w-full flex-col items-center justify-center lg:col-span-1'>
          <Typography variant={'mutedText'} as='p'>
            or
          </Typography>
        </div>

        <Card className='lg:col-span-3'>
          <CardHeader>
            <CardTitle>Create Organisation</CardTitle>
            <CardDescription>Create a new Organisation</CardDescription>
          </CardHeader>
          <CardContent>
            <div className='h-full w-full py-6'>
              <Button className='w-full' asChild>
                <Link href='/dashboard/organisations/create'>Create Organisation</Link>
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

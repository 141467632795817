import { FC } from 'react';
import { Alert, AlertDescription, AlertTitle, InfoCircledIcon, cn } from 'ui-lib';

export type NotificationsCountProps = {
  count: number;
  type: 'new' | 'dismissed';
  className?: string;
  tourId?: string;
};

export const NotificationsCount: FC<NotificationsCountProps> = ({ className, count, type, tourId }) => {
  return (
    <div className={cn('', className)}>
      <Alert data-tour-id={tourId}>
        <InfoCircledIcon className='h-4 w-4' />
        <AlertTitle>{count === 0 ? 'No notifications' : `Notifications`}</AlertTitle>
        <AlertDescription>
          You have <span className='font-semibold'>{count}</span> {type} notification{count === 1 ? '' : 's'}
        </AlertDescription>
      </Alert>
    </div>
  );
};

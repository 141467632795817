'use client';

import { FC } from 'react';
import { Sheet, SheetTrigger, Button, HamburgerMenuIcon, SheetContent } from 'ui-lib';

interface MobileSidebarProps {
  children: React.ReactNode;
}

export const MobileSidebar: FC<MobileSidebarProps> = ({ children }) => {
  return (
    <Sheet>
      <div className='md:hidden'>
        <SheetTrigger asChild>
          <Button variant='ghost' className='-ml-6 p-0 px-2'>
            <HamburgerMenuIcon className='text-muted-foreground h-6 w-6' />
          </Button>
        </SheetTrigger>
      </div>
      <SheetContent side='left'>{children}</SheetContent>
    </Sheet>
  );
};

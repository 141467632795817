'use client';

import {
  Step,
  StepTypeEnum,
  adjustUTCDateToLocalTimezone,
  getFormattedDateAndTimeString,
  getRelativeTimeString,
} from '@/common';
import { Route } from 'next';
import Link from 'next/link';
import { FC } from 'react';
import { Badge, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, Typography, cn } from 'ui-lib';
import { StepEditModal } from './StepEditModal';
import { StepIcon } from './StepIcon';
import { getStepBadgeVariant, getStepStatusText, stepTypeToText } from './utils';

type StepCardProps =
  | {
      type: 'overview';
      step: Step;
      workflowCount: number;
      usedCount: number;
      tourId?: string;
    }
  | {
      type: 'editable';
      step: Step;
      canEditStep: boolean;
      tourId?: string;
    }
  | {
      type: 'display';
      step: Step;
      tourId?: string;
    };

const getStepViewLink = (step: Step): Route<any> => {
  const route = `/dashboard/organisations/${step.ownerOrganisationId ?? ''}/templates/steps/${step.id ?? ''}` as const;

  return route satisfies Route<typeof route>;
};

export const StepOverviewCard: FC<StepCardProps> = (props) => {
  const step = props.step;

  const stepViewLink = getStepViewLink(step);

  const statusText = getStepStatusText(step);
  const badgeVariant = getStepBadgeVariant(step);

  const adjustedCreatedDate = adjustUTCDateToLocalTimezone(new Date(step.createdAt as unknown as string));
  const formattedCreatedDateTime = getFormattedDateAndTimeString(adjustedCreatedDate);
  const relativeCreatedDate = getRelativeTimeString(adjustedCreatedDate);

  const adjustedUpdatedDate = adjustUTCDateToLocalTimezone(new Date(step.updatedAt as unknown as string));
  const formattedUpdatedDateTime = getFormattedDateAndTimeString(adjustedUpdatedDate);
  const relativeUpdatedDate = getRelativeTimeString(adjustedUpdatedDate);

  return (
    <div
      data-tour-id={props.tourId}
      className='bg-card text-card-foreground w-full rounded-md border px-4 py-2 pt-4 shadow'
    >
      <div
        className={cn(
          'xs:flex-row xs:items-start flex flex-col-reverse items-start justify-between gap-4 space-x-2 pb-2',
        )}
      >
        <div className={cn('flex flex-col items-start justify-start')}>
          <div className='mb-2 flex h-6 w-full flex-shrink flex-row items-center justify-start'>
            <StepIcon stepType={step?.type ?? StepTypeEnum.Task} className='mr-4 h-full' />
            <Typography variant='smallText'>{stepTypeToText(step.type ?? StepTypeEnum.Task)}</Typography>
          </div>
          <Typography variant='h4'>{step.name}</Typography>
          <p>{step.description}</p>
          {step.type === StepTypeEnum.Form && step.form?.formTemplate?.name ? (
            <p className='pt-2'>
              <Typography variant='smallText' asChild>
                <Link
                  href={`/dashboard/organisations/${step.ownerOrganisationId}/templates/forms/${step.form?.formTemplateId}`}
                >
                  {step.form?.formTemplate?.name}
                </Link>
              </Typography>
            </p>
          ) : null}
        </div>

        <div className={cn('flex flex-col items-end justify-start')}>
          <Badge className='flex w-full items-center justify-center text-sm' variant={badgeVariant}>
            {statusText}
          </Badge>
          <div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Typography variant='mutedText' className='text-xs'>
                    Created: {relativeCreatedDate}
                  </Typography>
                </TooltipTrigger>
                <TooltipContent>{formattedCreatedDateTime}</TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <br />

            {formattedCreatedDateTime !== formattedUpdatedDateTime ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Typography variant='mutedText' className='text-xs'>
                      Updated: {relativeUpdatedDate}
                    </Typography>
                  </TooltipTrigger>
                  <TooltipContent>{formattedUpdatedDateTime}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : null}
          </div>
        </div>
      </div>

      <div className='flex items-center justify-between'>
        {props.type === 'overview' ? (
          <div className='flex items-start justify-start space-x-2'>
            <Typography variant='mutedText'>Workflows: {props.workflowCount}</Typography>
            <Typography variant='mutedText'>Matters: {props.usedCount}</Typography>
          </div>
        ) : (
          <div></div>
        )}
        {props.type === 'overview' && stepViewLink ? (
          <Link className='font-medium underline-offset-4 hover:underline' href={stepViewLink}>
            view
          </Link>
        ) : null}

        {props.type === 'editable' ? <StepEditModal step={step} canEditStep={props.canEditStep} /> : null}
      </div>
    </div>
  );
};

'use client';

import { UserProfile, getUserFullName } from '@/common';
import Link from 'next/link';
import { FC } from 'react';
import { Typography, cn } from 'ui-lib';
import { UserAvatar } from '../UserAvatar';
export type EnquiryMessageHeaderProps = {
  userProfile: UserProfile;
  createdById?: string;
  createdAt?: Date;
  className?: string;
  showContextMenu?: boolean; // TODO
  dateStyle?: 'full' | 'long' | 'medium' | 'short';
  timeStyle?: 'full' | 'long' | 'medium' | 'short';
};

export const EnquiryMessageHeader: FC<EnquiryMessageHeaderProps> = ({
  className,
  userProfile,
  createdAt,
  createdById,
  dateStyle = 'medium',
  timeStyle,
}) => {
  // const currentUserProfile = useContext(UserProfileContext);

  const fullName = getUserFullName(userProfile?.user);

  const enquiryDate = new Intl.DateTimeFormat('en-GB', {
    dateStyle,
    timeStyle,
  }).format(new Date(createdAt as unknown as string));

  // TODO: Confirm if editting is even needed
  // const canEdit = currentUserProfile?.userId === userProfile.userId;

  return (
    <div className={cn('flex flex-row items-center justify-between space-x-2 pb-2', className)}>
      <div className='flex flex-row items-center justify-start space-x-2'>
        <UserAvatar className='h-10 w-10' userProfile={userProfile} />
        <div className='flex flex-col items-start justify-evenly'>
          <Link href={`/dashboard/users/${createdById}`}>
            <Typography variant='smallText'>{fullName}</Typography>
          </Link>
          <Typography variant='mutedText' className='text-xs'>
            {enquiryDate}
          </Typography>
        </div>
      </div>
      {/* {showContextMenu ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='ghost' size='icon' className='rounded-full'>
              <DotsVerticalIcon className='h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='' forceMount>
            <DropdownMenuItem
              className='flex flex-row justify-between'
              onSelect={() => {
                alert('TODO');
              }}
            >
              Reply <ReplyIcon className='text-muted-foreground h-4 w-4' />
            </DropdownMenuItem>
            {canEdit ? (
              <DropdownMenuItem
                className='flex flex-row justify-between'
                onSelect={() => {
                  alert('TODO');
                }}
              >
                Edit <Pencil2Icon className='text-muted-foreground h-4 w-4' />
              </DropdownMenuItem>
            ) : null}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : null} */}
    </div>
  );
};

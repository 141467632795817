'use client';

import { Enquiry, MessageTypeEnum, messagesTag, toastTimeout } from '@/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/navigation';
import { FC, KeyboardEventHandler, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, FormControl, FormField, FormItem, FormMessage, Textarea, toast } from 'ui-lib';
import { z } from 'zod';
import { createMessage } from '@/actions';
import { useSWRConfig } from 'swr';
import { EnquiryChatUploadModal } from './EnquiryChatUploadModal';

interface EnquiryChatReplyProps {
  enquiry: Enquiry;
  canMessage: boolean;
  canCreateDocument: boolean;
}

const createMessageFormSchema = z.object({
  body: z
    .string()
    .min(2, {
      message: 'Message must be at least 2 characters.',
    })
    .max(500, {
      message: 'Message must not be longer than 500 characters.',
    }),
});

type CreateMessageFormValues = z.infer<typeof createMessageFormSchema>;

export const EnquiryChatReply: FC<EnquiryChatReplyProps> = ({ enquiry, canMessage, canCreateDocument }) => {
  const router = useRouter();
  const { mutate } = useSWRConfig();

  const [isLoading, setIsLoading] = useState<boolean>();

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const defaultValues: Partial<CreateMessageFormValues> = {
    body: '',
  };

  const form = useForm<CreateMessageFormValues>({
    resolver: zodResolver(createMessageFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  const onSubmit = async (data: CreateMessageFormValues) => {
    setIsLoading(true);

    try {
      const res = await createMessage({
        body: data.body,
        enquiryId: enquiry.id,
        type: MessageTypeEnum.Message,
      });

      if (res?.error) {
        throw res.error;
      }

      mutate(messagesTag());

      form.reset();
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
    router.refresh();
  };

  const submitOnEnter: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitButtonRef.current?.click();
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='flex h-full w-full space-x-4 p-4'>
        <div className='flex h-full flex-col items-start justify-center'>
          <EnquiryChatUploadModal
            enquiryId={enquiry.id ?? ''}
            matterId={enquiry.matterId ?? ''}
            canCreateDocument={canCreateDocument}
          />
        </div>
        <div className='flex h-full w-full'>
          <FormField
            control={form.control}
            name='body'
            disabled={!canMessage}
            render={({ field }) => (
              <FormItem className='h-full w-full'>
                <FormControl>
                  <Textarea onKeyUp={submitOnEnter} placeholder='Your reply...' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className='flex h-full flex-col items-start justify-center'>
          <Button ref={submitButtonRef} type='submit' disabled={isLoading || !canMessage}>
            Reply
          </Button>
        </div>
      </form>
    </Form>
  );
};

'use client';

import { InviteMeta, toastTimeout } from '@/common';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import { Card, Button, toast } from 'ui-lib';
import { InvitedBy } from './InvitedBy';
import { InvitedTo } from './InvitedTo';
import { Route } from 'next';
import { acceptInvite, rejectInvite } from '@/actions';
import { UserAvatar } from '../UserAvatar';

interface InviteCardProps {
  inviteMeta?: InviteMeta;
  tourId?: string;
}

export const InviteCard: FC<InviteCardProps> = ({ inviteMeta, tourId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();

  if (!inviteMeta) {
    return null;
  }

  const { organisationProfile, matterDetail, requestedByUserProfile } = inviteMeta;

  const onRejectInvite = async (inviteId: string) => {
    setLoading(true);

    try {
      const res = await rejectInvite(inviteId);

      if (res?.error) {
        throw res.error;
      }

      toast.info(`Invite rejected`, {
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setLoading(false);
    router.refresh();
  };

  const onAcceptInvite = async (inviteId: string) => {
    setLoading(true);

    try {
      const res = await acceptInvite(inviteId);

      if (res?.error) {
        throw res.error;
      }

      toast.info(`Invite accepted`, {
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setLoading(false);
    router.refresh();
  };

  const inviteAlreadyProcessed =
    inviteMeta.invite?.acceptedAt !== null ||
    inviteMeta.invite?.cancelledAt !== null ||
    inviteMeta.invite?.rejectedAt !== null;

  let inviteMessage = '';

  if (requestedByUserProfile) {
    if (inviteAlreadyProcessed) {
      inviteMessage = 'invited you to join';
    } else {
      inviteMessage = 'has invited you to join';
    }
  } else {
    if (inviteAlreadyProcessed) {
      inviteMessage = 'You were asked to join';
    } else {
      inviteMessage = 'You have been asked to join';
    }
  }

  const buttonsDisabled = loading || inviteAlreadyProcessed;

  return (
    <Card
      data-tour-id={tourId}
      className='grid grid-cols-1 p-4 sm:flex sm:flex-row sm:items-center sm:justify-between sm:space-x-6'
    >
      <div className='flex flex-row items-center justify-start'>
        <UserAvatar userProfile={requestedByUserProfile} />
        <p>
          <InvitedBy userProfile={requestedByUserProfile} />
          <span className=''>{inviteMessage}&nbsp;</span>
          {organisationProfile ? (
            <>
              <span>organisation&nbsp;</span>
              <InvitedTo
                name={organisationProfile?.organisation?.name ?? ''}
                href={`/dashboard/organisations/${organisationProfile?.organisation?.id}` as Route}
              />
            </>
          ) : null}
          {matterDetail ? (
            <>
              <span>matter&nbsp;</span>
              <InvitedTo name={matterDetail?.matter?.name ?? ''} />
            </>
          ) : null}
        </p>
      </div>
      <div className='flex flex-row justify-between space-x-6 pt-4 sm:justify-end sm:pt-0'>
        <Button
          variant={'outlineDestructive'}
          disabled={buttonsDisabled}
          onClick={() => {
            onRejectInvite(inviteMeta.invite.id ?? '');
          }}
        >
          Reject
        </Button>
        <Button
          variant={'default'}
          disabled={buttonsDisabled}
          onClick={() => {
            onAcceptInvite(inviteMeta.invite.id ?? '');
          }}
        >
          Accept
        </Button>
      </div>
    </Card>
  );
};

'use client';

import Link from 'next/link';
import { useParams, useRouter } from 'next/navigation';
import { FC, useContext } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  FullWidthSpinner,
  Typography,
} from 'ui-lib';
import { UserProfileContext } from '../UserProfileContext';
import { InvitesCount, RequestInviteCard } from '../Invite';
import { InviteResourceEnum } from '@/common';
import { useInvitesPendingGet } from '@/common/gen/api-hooks';

export type ForbiddenErrorProps = {
  errorType: string;
  errorDescription: string;
};

type ProtectedParams = {
  matterId?: string;
  documentId?: string;
  organisationId?: string;
};

export const ForbiddenError: FC<ForbiddenErrorProps> = ({ errorType, errorDescription }) => {
  const router = useRouter();
  const userProfile = useContext(UserProfileContext);
  const params = useParams<ProtectedParams>();

  const { data: invites, isLoading: invitesGetLoading } = useInvitesPendingGet(
    {
      queryParams: {
        userId: userProfile?.userId ?? '',
      },
    },
    {},
    {
      refreshInterval: 60 * 1000, // refresh every x milliseconds
    },
  );

  if (invitesGetLoading) {
    return <FullWidthSpinner />;
  }

  let inviteResource: InviteResourceEnum | null = null;
  let resourceId: string = '';

  // TODO: Add fine grained access requesting in the future
  // i.e. request access to specific document
  if (params?.matterId) {
    inviteResource = InviteResourceEnum.Matter;
    resourceId = params.matterId;
  } else if (params?.organisationId) {
    inviteResource = InviteResourceEnum.Organisation;
    resourceId = params.organisationId;
  }

  const hasPendingInvites = invites?.meta?.total ?? 0 > 0;

  return (
    <div className='pt-6 sm:p-6'>
      <Typography variant='h2'>{errorType}</Typography>
      <Typography variant='lead'>{errorDescription}</Typography>

      <div className='flex flex-col items-stretch justify-start lg:grid lg:grid-cols-7 lg:items-stretch lg:justify-normal lg:pt-6'>
        {hasPendingInvites ? (
          <Card className='mt-6 lg:col-span-3 lg:mt-0'>
            <CardHeader>
              <CardTitle>You have pending invites</CardTitle>
              <CardDescription>You may have been invited to this resource</CardDescription>
            </CardHeader>
            <CardContent>
              <InvitesCount count={invites?.meta?.total ?? 0} />
            </CardContent>
            <CardFooter className='flex justify-end'>
              <Button asChild>
                <Link href={`/dashboard/users/${userProfile?.userId}/invites`}>View Invites</Link>
              </Button>
            </CardFooter>
          </Card>
        ) : null}

        {hasPendingInvites && inviteResource ? (
          <div className='flex w-full items-center justify-center pt-6 lg:col-span-1 lg:pt-0'>
            <Typography variant={'mutedText'} as='p'>
              or
            </Typography>
          </div>
        ) : null}

        {inviteResource ? (
          <RequestInviteCard
            className='mt-6 h-full lg:col-span-3 lg:mt-0'
            inviteResource={inviteResource}
            resourceId={resourceId}
          />
        ) : null}
      </div>
      <Button
        variant='link'
        className='pl-0 pt-6'
        onClick={() => {
          router.back();
        }}
      >
        Back
      </Button>
    </div>
  );
};

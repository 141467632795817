import { Enquiry, EnquiryStatusEnum } from '@/common';

export const getEnquiryStatusText = (enquiry: Enquiry): string => {
  let statusText = '';

  if (enquiry.status === EnquiryStatusEnum.Open) {
    statusText = 'Open';
  } else if (enquiry.status === EnquiryStatusEnum.InReview) {
    statusText = 'In Review';
  } else if (enquiry.status === EnquiryStatusEnum.Resolved) {
    statusText = 'Resolved';
  }

  return statusText;
};

export const getEnquiryBadgeVariant = (enquiry: Enquiry): 'default' | 'secondary' | 'outline' => {
  let badgeVariant: 'default' | 'secondary' | 'outline' = 'default';

  if (enquiry.status === EnquiryStatusEnum.InReview) {
    badgeVariant = 'secondary';
  } else if (enquiry.status === EnquiryStatusEnum.Resolved) {
    badgeVariant = 'outline';
  }

  return badgeVariant;
};

'use client';

import { cancelInviteRequest } from '@/actions';
import { InviteRequestMeta, toastTimeout } from '@/common';
import { Route } from 'next';
import { useRouter } from 'next/navigation';
import { FC } from 'react';
import { Button, Card, toast } from 'ui-lib';
import { InvitedTo } from './InvitedTo';

interface InviteRequestCardProps {
  inviteRequestMeta?: InviteRequestMeta;
  tourId?: string;
}

export const InviteRequestCard: FC<InviteRequestCardProps> = ({ inviteRequestMeta, tourId }) => {
  const router = useRouter();

  if (!inviteRequestMeta) {
    return null;
  }

  const { organisationProfile, matterDetail } = inviteRequestMeta;

  const onCancelInviteRequest = async (inviteId: string) => {
    try {
      const res = await cancelInviteRequest(inviteId);

      if (res?.error) {
        throw res.error;
      }

      toast.info(`Invite request cancelled`, {
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    router.refresh();
  };

  const inviteMessage = 'You have requested to join';

  return (
    <Card
      data-tour-id={tourId}
      className='grid grid-cols-1 p-4 sm:flex sm:flex-row sm:items-center sm:justify-between sm:space-x-6'
    >
      <div className='flex flex-row items-center justify-start'>
        <p>
          <span className=''>{inviteMessage}&nbsp;</span>
          {organisationProfile ? (
            <>
              <span>organisation&nbsp;</span>
              <InvitedTo
                name={organisationProfile?.organisation?.name ?? ''}
                href={`/dashboard/organisations/${organisationProfile?.organisation?.id}` as Route}
              />
            </>
          ) : null}
          {matterDetail ? (
            <>
              <span>matter&nbsp;</span>
              <InvitedTo name={matterDetail?.matter?.name ?? ''} />
            </>
          ) : null}
        </p>
      </div>
      <div className='flex flex-row justify-between space-x-6 pt-4 sm:justify-end sm:pt-0'>
        <Button
          variant={'outlineDestructive'}
          onClick={() => {
            onCancelInviteRequest(inviteRequestMeta.inviteRequest.id ?? '');
          }}
        >
          Cancel
        </Button>
      </div>
    </Card>
  );
};

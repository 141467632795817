'use client';

import { createInviteRequest } from '@/actions';
import { InviteResourceEnum, toastTimeout } from '@/common';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import { Button, Card, CardDescription, CardFooter, CardHeader, CardTitle, cn, toast } from 'ui-lib';

export type RequestInviteProps = {
  className?: string;
  inviteResource: InviteResourceEnum;
  resourceId: string;
};

export const RequestInviteCard: FC<RequestInviteProps> = ({ className, inviteResource, resourceId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useRouter();

  let inviteResourceLabel = 'resource';

  if (inviteResource === InviteResourceEnum.Organisation) {
    inviteResourceLabel = 'organisation';
  } else if (inviteResource === InviteResourceEnum.Matter) {
    inviteResourceLabel = 'matter';
  }

  const onRequestInvite = async () => {
    setIsLoading(true);

    try {
      const res = await createInviteRequest({
        inviteResource,
        inviteToId: resourceId,
      });

      if (res?.error) {
        throw res.error;
      }

      toast.info(`Invite request created`, {
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    router.refresh();

    setIsLoading(false);
  };

  return (
    <Card className={cn('flex h-full flex-col', className)}>
      <CardHeader className='h-fit'>
        <CardTitle>Request invite</CardTitle>
        <CardDescription>Request an invite to this {inviteResourceLabel}.</CardDescription>
      </CardHeader>

      <CardFooter className='ml-2 flex h-full items-end justify-end'>
        <Button
          disabled={isLoading}
          onClick={() => {
            onRequestInvite();
          }}
        >
          Request
        </Button>
      </CardFooter>
    </Card>
  );
};

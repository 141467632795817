export const getErrorText = (statusCode: number): { errorType: string; errorDescription: string } => {
  let errorType = 'Error';
  let errorDescription = 'An unexpected error occurred';

  if (statusCode === 404) {
    errorType = 'Not Found';
    errorDescription = 'Could not find requested resource';
  }

  if (statusCode === 403) {
    errorType = 'Unauthorised';
    errorDescription = 'You do not have access to the requested resource';
  }

  return { errorType, errorDescription };
};

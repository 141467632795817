'use client';

import { createStepTask } from '@/actions';
import { toastTimeout } from '@/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input, Textarea, toast } from 'ui-lib';
import { z } from 'zod';

interface StepTaskFormProps {
  stepId: string;
  canCreateSteps: boolean;
}

const createStepTaskFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: 'Name must be at least 2 characters.',
    })
    .max(100, {
      message: 'Name must not be longer than 100 characters.',
    }),
  description: z
    .string()
    .min(2, {
      message: 'Description must be at least 2 characters.',
    })
    .max(300, {
      message: 'Description must not be longer than 300 characters.',
    }),
});

type CreateStepTaskFormValues = z.infer<typeof createStepTaskFormSchema>;

export const StepTaskForm: FC<StepTaskFormProps> = ({ stepId, canCreateSteps }) => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState<boolean>();

  const defaultValues: Partial<CreateStepTaskFormValues> = {
    name: '',
    description: '',
  };

  const form = useForm<CreateStepTaskFormValues>({
    resolver: zodResolver(createStepTaskFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  const onSubmit = async (data: CreateStepTaskFormValues) => {
    setIsLoading(true);

    try {
      const res = await createStepTask({
        name: data.name,
        description: data.description,
        stepId: stepId,
      });

      if (res?.error) {
        throw res.error;
      }

      form.reset();

      toast.success(`Step Task Created`, {
        description: `${data.name} created`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });

      router.refresh();
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
  };

  if (!canCreateSteps) {
    return null;
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-2'>
        <FormField
          control={form.control}
          name='name'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder='Name of the step task' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='description'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea placeholder='Step task description...' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex flex-row justify-end'>
          <Button type='submit' disabled={isLoading}>
            Create
          </Button>
        </div>
      </form>
    </Form>
  );
};

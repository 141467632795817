'use client';

import { Route } from 'next';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { FC } from 'react';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from 'ui-lib';

export type NotificationStatusSelectProps = {
  className?: string;
};

export const NotificationStatusSelect: FC<NotificationStatusSelectProps> = ({ className }) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const defaultValue = searchParams?.get('dismissed') === 'true' ? 'dismissed' : 'new';

  const onStatusChange = (value: 'new' | 'dismissed'): void => {
    const dismissed = value === 'dismissed';

    if (!dismissed && searchParams?.get('dismissed') !== 'true') {
      return;
    }

    if (dismissed && searchParams?.get('dismissed') === 'true') {
      return;
    }

    const search = new URLSearchParams(searchParams ?? {});
    search.delete('dismissed');
    search.set('dismissed', dismissed ? 'true' : 'false');

    router.replace(`${pathname}?${search.toString()}` as Route, { scroll: true });
  };

  return (
    <Select defaultValue={defaultValue} onValueChange={onStatusChange}>
      <SelectTrigger className={className}>
        <SelectValue placeholder='Notification status' />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Status</SelectLabel>
          <SelectItem value='new'>New</SelectItem>
          <SelectItem value='dismissed'>Dismissed</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

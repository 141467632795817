'use client';

import { Route } from 'next';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { ChangeEvent, FC, useRef } from 'react';
import { Input, InputProps } from 'ui-lib';

type SearchProps = InputProps;

export const Search: FC<SearchProps> = (props) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const currentSearchValue = searchParams?.get('search') ?? '';

  const setSearch = (search: string) => {
    const urlSearchParams = new URLSearchParams(searchParams ?? {});

    if (!search || !search?.length) {
      urlSearchParams.delete('search');
      router.replace(`${pathname}?${urlSearchParams.toString()}` as Route, { scroll: true });
      return;
    }

    urlSearchParams.delete('search');
    urlSearchParams.set('search', search);

    router.replace(`${pathname}?${urlSearchParams.toString()}` as Route, { scroll: true });
  };

  const throttleDelay = 500;

  const timeoutRef = useRef<NodeJS.Timeout | null>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        setSearch(event.target.value);

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = null;
      }, throttleDelay);
    }
  };

  return (
    <Input type='text' defaultValue={currentSearchValue} placeholder='Search...' onChange={handleChange} {...props} />
  );
};

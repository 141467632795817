'use client';

import { SidebarLink } from '@/components/Sidebar';
import type { Route } from 'next';
import { FC } from 'react';

interface SidebarSectionLinksProps {
  sidebarSections: SidebarSection[];
}

export interface SidebarLinkButton {
  href: Route<any>; // https://github.com/vercel/next.js/issues/47689
  label: string;
  icon: React.ReactNode;
  subLinks?: SidebarLinkButton[];
  tourId?: string;
}

export interface SidebarSection {
  section: string;
  links: SidebarLinkButton[];
}

export const SidebarSectionLinks: FC<SidebarSectionLinksProps> = ({ sidebarSections }): JSX.Element => {
  return (
    <>
      {sidebarSections.map(({ section, links }) => (
        <div key={`sidebar_section_${section}`} className='py-0'>
          {section.length ? <h2 className='mb-2 pr-4 text-lg font-semibold tracking-tight'>{section}</h2> : null}
          <div className='space-y-1'>
            {links.map(({ href, label, icon, subLinks, tourId }) => (
              <SidebarLink tourId={tourId} key={`side_link_${href}`} href={href} subLinks={subLinks}>
                {icon}
                <span className='ml-2'>{label}</span>
              </SidebarLink>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

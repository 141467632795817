'use client';

import { createStep, updateStep } from '@/actions';
import { Step, StepPost, StepStatusEnum, StepTypeEnum, toastTimeout, useFormTemplatesGet } from '@/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
  toast,
} from 'ui-lib';
import { z } from 'zod';
import { stepStatusToText, stepTypeToText } from './utils';

type StepFormCreateProps = {
  type: 'create';
  organisationId: string;
  hasPermission: boolean;
};

type StepFormEditProps = {
  type: 'edit';
  stepId: string;
  step: Step;
  hasPermission: boolean;
  onSuccess: () => void;
};

export type StepFormProps = StepFormCreateProps | StepFormEditProps;

const createStepFormSchema = z
  .object({
    type: z.nativeEnum(StepTypeEnum),
    name: z
      .string()
      .min(2, 'Name must be at least 2 characters.')
      .max(100, 'Name must not be longer than 100 characters.'),
    description: z
      .string()
      .min(2, 'Description must be at least 2 characters.')
      .max(100, 'Description must not be longer than 100 characters.'),
    formTemplateId: z.string(),
    status: z.nativeEnum(StepStatusEnum),
  })
  .superRefine((data, ctx) => {
    if (data.type === StepTypeEnum.Form) {
      if (!data.formTemplateId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['formTemplateId'],
          message: 'You must select a form template.',
        });
      }
    }
  });

type CreateStepFormValues = z.infer<typeof createStepFormSchema>;

export const StepForm: FC<StepFormProps> = (props) => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState<boolean>();

  const { data: formTemplates } = useFormTemplatesGet({
    queryParams: {
      organisationId: props.type === 'create' ? props.organisationId : props.step?.ownerOrganisationId,
      limit: 0,
    },
  });

  const defaultValues: Partial<CreateStepFormValues> = {
    type: StepTypeEnum.Task,
    name: '',
    description: '',
    formTemplateId: '',
    status: StepStatusEnum.Draft,
  };

  if (props.type === 'edit' && props.step) {
    defaultValues.type = props.step.type ?? StepTypeEnum.Task;
    defaultValues.name = props.step?.name ?? '';
    defaultValues.description = props.step?.description ?? '';
    (defaultValues.formTemplateId = props.step?.form?.formTemplateId ?? ''), (defaultValues.status = props.step.status);
  }

  const form = useForm<CreateStepFormValues>({
    resolver: zodResolver(createStepFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  const stepType = useWatch({ control: form.control, name: 'type' });

  const onSubmit = async (data: CreateStepFormValues) => {
    setIsLoading(true);

    const supportedStepTypes = [
      StepTypeEnum.DocumentUpload,
      StepTypeEnum.Enquiry,
      StepTypeEnum.Form,
      StepTypeEnum.Task,
    ];

    if (!supportedStepTypes.includes(data.type)) {
      toast.error(`Error`, {
        description: `Step type ${stepTypeToText(data.type)} not implemented`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });

      setIsLoading(false);
      return;
    }

    try {
      if (props.type === 'edit') {
        await updateExistingStep(props.stepId, data);
      }

      if (props.type === 'create') {
        await createNewStep(data);
      }

      router.refresh();
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
  };

  const createNewStep = async (data: CreateStepFormValues) => {
    // pointless as we only call this fn if it is create - TS 5.4 might fix this
    if (props.type !== 'create') {
      return;
    }

    const stepPost: StepPost = {
      name: data.name,
      description: data.description,
      type: data.type,
      ownerOrganisationId: props.organisationId,
    };

    if (data.type === StepTypeEnum.Form) {
      stepPost.form = {
        formTemplateId: data.formTemplateId,
      };
    }

    const res = await createStep(stepPost);

    if (res?.error) {
      throw res.error;
    }

    form.reset();

    toast.success(`Step Created`, {
      description: `${data.name} created`,
      closeButton: true,
      duration: toastTimeout,
      invert: true,
    });
  };

  const updateExistingStep = async (stepId: string, data: CreateStepFormValues) => {
    const res = await updateStep(stepId ?? '', {
      name: data.name,
      description: data.description,
      status: data.status,
    });

    if (res?.error) {
      throw res.error;
    }

    toast.success(`Step Updated`, {
      description: `${data.name} updated`,
      closeButton: true,
      duration: toastTimeout,
      invert: true,
    });

    if (props.type === 'edit') {
      props.onSuccess();
    }
  };

  if (!props.hasPermission) {
    return null;
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='w-full space-y-2'>
        {props.type === 'create' ? (
          <FormField
            control={form.control}
            name='type'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Type</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='Select the step type' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value={StepTypeEnum.DocumentUpload}>
                      {stepTypeToText(StepTypeEnum.DocumentUpload)}
                    </SelectItem>
                    <SelectItem value={StepTypeEnum.Enquiry}>{stepTypeToText(StepTypeEnum.Enquiry)}</SelectItem>
                    <SelectItem value={StepTypeEnum.Form}>{stepTypeToText(StepTypeEnum.Form)}</SelectItem>
                    <SelectItem value={StepTypeEnum.Task}>{stepTypeToText(StepTypeEnum.Task)}</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : null}

        <FormField
          control={form.control}
          name='name'
          render={({ field }) => (
            <FormItem>
              <FormLabel>{stepType === StepTypeEnum.Enquiry ? 'Title' : 'Name'}</FormLabel>
              <FormControl>
                <Input
                  placeholder={`${stepType === StepTypeEnum.Enquiry ? 'Title' : 'Name'} of the ${stepType === StepTypeEnum.Enquiry ? 'Enquiry' : 'Step'}`}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='description'
          render={({ field }) => (
            <FormItem>
              <FormLabel>{stepType === StepTypeEnum.Enquiry ? 'Body' : 'Description'}</FormLabel>
              <FormControl>
                <Textarea
                  placeholder={stepType === StepTypeEnum.Enquiry ? 'Your enquiry...' : 'Step description...'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {stepType === StepTypeEnum.Form && formTemplates?.data?.length ? (
          <FormField
            control={form.control}
            name='formTemplateId'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Form Template</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='Select a form template' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {formTemplates.data?.map(({ id, name }) => (
                      <SelectItem key={id} value={id ?? ''}>
                        {name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : null}

        {props.type === 'edit' ? (
          <FormField
            control={form.control}
            name='status'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Status</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='Select the step status' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value={StepStatusEnum.Draft}>{stepStatusToText(StepStatusEnum.Draft)}</SelectItem>
                    <SelectItem value={StepStatusEnum.Published}>
                      {stepStatusToText(StepStatusEnum.Published)}
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : null}

        <div className='flex flex-row justify-end'>
          <Button type='submit' disabled={isLoading}>
            {props.type === 'edit' ? 'Update' : 'Create'}
          </Button>
        </div>
      </form>
    </Form>
  );
};

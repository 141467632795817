'use client';

import { FC } from 'react';
import { Button, Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from 'ui-lib';
import { StepForm } from './StepForm';

interface StepCreateModalProps {
  organisationId: string;
  canCreateSteps: boolean;
  tourId?: string;
}

export const StepCreateModal: FC<StepCreateModalProps> = ({ organisationId, canCreateSteps, tourId }) => {
  if (!canCreateSteps) {
    return null;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button data-tour-id={tourId}>New Step</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Step</DialogTitle>
          <DialogDescription>Create a new step template</DialogDescription>
        </DialogHeader>
        <StepForm type='create' organisationId={organisationId} hasPermission={canCreateSteps} />
      </DialogContent>
    </Dialog>
  );
};

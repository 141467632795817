'use client';

import { EnquiryMeta, EnquiryStatusEnum } from '@/common';
import { Route } from 'next';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { FC } from 'react';
import {
  Badge,
  Button,
  CheckIcon,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  PlusCircledIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  cn,
} from 'ui-lib';

interface EnquiryFiltersProps {
  meta: EnquiryMeta;
}

export const EnquiryFilters: FC<EnquiryFiltersProps> = ({ meta }) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const title = 'Status';

  const selectedValuesArr = searchParams
    ?.get('enquiryStatus')
    ?.split(',')
    .filter((s) => !!s) as EnquiryStatusEnum[];

  const selectedValues = new Set<EnquiryStatusEnum>(selectedValuesArr);

  const options: {
    label: string;
    value: EnquiryStatusEnum;
    metaKey: keyof EnquiryMeta;
    icon?: React.ComponentType<{ className?: string }>;
  }[] = [
    {
      label: 'Open',
      value: EnquiryStatusEnum.Open,
      metaKey: 'open',
    },
    {
      label: 'In Review',
      value: EnquiryStatusEnum.InReview,
      metaKey: 'inReview',
    },
    {
      label: 'Resolved',
      value: EnquiryStatusEnum.Resolved,
      metaKey: 'resolved',
    },
  ];

  const setFilterOptions = (filters: EnquiryStatusEnum[] | null) => {
    const search = new URLSearchParams(searchParams ?? {});

    if (!filters || !filters?.length) {
      search.delete('enquiryStatus');
      router.replace(`${pathname}?${search.toString()}` as Route, { scroll: true });
      return;
    }

    search.delete('enquiryStatus');
    search.set('enquiryStatus', filters.join(','));

    router.replace(`${pathname}?${search.toString()}` as Route, { scroll: true });
  };

  return (
    <div>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant='outline' size='sm' className='h-8 border-dashed'>
            <PlusCircledIcon className='mr-2 h-4 w-4' />
            {title}
            {selectedValues?.size > 0 && (
              <>
                <Separator orientation='vertical' className='mx-2 h-4' />
                <Badge variant='secondary' className='rounded-sm px-1 font-normal lg:hidden'>
                  {selectedValues.size}
                </Badge>
                <div className='hidden space-x-1 lg:flex'>
                  {selectedValues.size > 3 ? (
                    <Badge variant='secondary' className='rounded-sm px-1 font-normal'>
                      {selectedValues.size} selected
                    </Badge>
                  ) : (
                    options
                      .filter((option) => selectedValues.has(option.value))
                      .map((option) => (
                        <Badge variant='secondary' key={option.value} className='rounded-sm px-1 font-normal'>
                          {option.label}
                        </Badge>
                      ))
                  )}
                </div>
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-[200px] p-0' align='start'>
          <Command>
            <CommandInput placeholder={title} />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = selectedValues.has(option.value);
                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => {
                        if (isSelected) {
                          selectedValues.delete(option.value);
                        } else {
                          selectedValues.add(option.value);
                        }
                        const filterValues = Array.from(selectedValues);

                        setFilterOptions(filterValues);
                      }}
                    >
                      <div
                        className={cn(
                          'border-primary mr-2 flex h-4 w-4 items-center justify-center rounded-sm border',
                          isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible',
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>
                      {option.icon && <option.icon className='text-muted-foreground mr-2 h-4 w-4' />}
                      <span>{option.label}</span>
                      <span className='ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs'>
                        {meta[option.metaKey]}
                      </span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              {selectedValues.size > 0 && (
                <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem
                      onSelect={() => {
                        setFilterOptions(null);
                      }}
                      className='justify-center text-center'
                    >
                      Clear filters
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

'use client';

import { Enquiry, MessageTypeEnum, toastTimeout } from '@/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Separator,
  Textarea,
  toast,
} from 'ui-lib';
import { z } from 'zod';
import { createMessage } from '@/actions';

interface EnquiryReplyCardProps {
  enquiry: Enquiry;
}

const createMessageFormSchema = z.object({
  body: z
    .string()
    .min(2, {
      message: 'Body must be at least 2 characters.',
    })
    .max(300, {
      message: 'Body must not be longer than 300 characters.',
    }),
});

type CreateMessageFormValues = z.infer<typeof createMessageFormSchema>;

export const EnquiryReplyCard: FC<EnquiryReplyCardProps> = ({ enquiry }) => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState<boolean>();

  const defaultValues: Partial<CreateMessageFormValues> = {
    body: '',
  };

  const form = useForm<CreateMessageFormValues>({
    resolver: zodResolver(createMessageFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  const onSubmit = async (data: CreateMessageFormValues) => {
    setIsLoading(true);

    try {
      const res = await createMessage({
        body: data.body,
        enquiryId: enquiry.id,
        type: MessageTypeEnum.Message,
      });

      if (res?.error) {
        throw res.error;
      }

      form.reset();
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
    router.refresh();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-2 py-2'>
        <Separator />
        <FormField
          control={form.control}
          name='body'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Reply</FormLabel>
              <FormControl>
                <Textarea placeholder='Your reply...' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex flex-row justify-end'>
          <Button type='submit' disabled={isLoading}>
            Reply
          </Button>
        </div>
      </form>
    </Form>
  );
};

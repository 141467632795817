import { OrganisationProfile as OrganisationProfileData } from '@/common';
import Link from 'next/link';
import Image from 'next/image';
import { FC } from 'react';
import {
  Card,
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  Button,
  Pencil2Icon,
  TooltipContent,
  Typography,
  cn,
} from 'ui-lib';

interface OrganisationProfileCardProps {
  organisationProfile: OrganisationProfileData;
  canEdit: boolean;
  tourId?: string;
}

const getProfileFieldItem = (fieldName: string, field?: string, href?: string): JSX.Element | null => {
  if (!field || !field?.length) {
    return null;
  }

  const renderAsLink = href && href?.length > 0;

  let fieldComponent = (
    <Typography
      as='span'
      variant='mutedText'
      className={cn({
        'text-primary': renderAsLink,
      })}
    >
      {field}
    </Typography>
  );

  if (href) {
    const externalHref = href.includes('http') ? href : `https://${href}`;

    fieldComponent = (
      <a href={externalHref} target='_blank' className='text-primary hover:underline'>
        {fieldComponent}
      </a>
    );
  }

  return (
    <p>
      <Typography as='span' variant='smallText'>
        {fieldName}:{' '}
      </Typography>
      {fieldComponent}
    </p>
  );
};

export const OrganisationProfileCard: FC<OrganisationProfileCardProps> = ({ organisationProfile, canEdit, tourId }) => {
  const organisationNames = organisationProfile.organisation?.name?.split?.(' ') ?? [''];

  const organisationInitials = `${organisationNames?.[0]?.charAt(0) ?? ''}${organisationNames?.[1]?.charAt(0) ?? organisationNames?.[0]?.charAt(1) ?? ''}`;

  return (
    <Card data-tour-id={tourId} className='relative col-span-2'>
      {canEdit ? (
        <div className='absolute right-0 top-0 flex flex-row items-start justify-end'>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button variant={'ghost'} size={'tight'} asChild>
                  <Link href={`/dashboard/organisations/${organisationProfile.organisationId}/manage-profile`}>
                    <Pencil2Icon className='h-6 w-auto' />
                  </Link>
                </Button>
              </TooltipTrigger>
              <TooltipContent>Edit profile content</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ) : null}
      <div className='grid grid-cols-1 gap-6 p-4 md:grid-cols-3'>
        <div className='relative col-span-1 flex flex-col items-center justify-center'>
          <Image
            src={
              organisationProfile.organisationLogo?.resizedDownloadUrl ??
              organisationProfile.organisationLogo?.downloadUrl ??
              `https://placehold.co/400.png?text=${organisationInitials}`
            }
            alt={`profile avatar`}
            width={200}
            height={200}
            className='border-muted-foreground h-auto rounded-md border object-contain md:w-full'
          />
        </div>
        <div className='col-span-2 flex flex-col'>
          <Typography as='p' variant={'largeText'} className='pb-2'>
            {organisationProfile.organisation?.name}
          </Typography>
          <p className='pb-2 pl-2'>
            <Typography as='span' variant={'mutedText'}>
              {organisationProfile.bio ? organisationProfile.bio : 'no bio'}
            </Typography>
          </p>
          {getProfileFieldItem('Website', organisationProfile.website, organisationProfile.website)}
          {getProfileFieldItem('Phone', organisationProfile.phone, `tel:${organisationProfile.phone}`)}
          {getProfileFieldItem('SRA#', organisationProfile.organisation?.sraLicenseNumber)}
          {getProfileFieldItem('CLC#', organisationProfile.organisation?.clcLicenseNumber)}
          {getProfileFieldItem('Location', organisationProfile.organisation?.registeredOffice?.city)}
        </div>
      </div>
    </Card>
  );
};

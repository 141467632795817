'use client';

import { UserAvatarMenu } from '@/components';
import { FC, useContext } from 'react';
import { Avatar, Skeleton } from 'ui-lib';
import { UserProfileContext } from '../UserProfileContext';

export const dynamic = 'force-dynamic';

interface NavAuthProps {
  forceLogin?: boolean;
}

export const NavAuth: FC<NavAuthProps> = () => {
  const userProfile = useContext(UserProfileContext);

  const signOutUrl = '/services/backend/v1/auth/sign-out';

  if (!userProfile) {
    return (
      <Skeleton>
        <Avatar />
      </Skeleton>
    );
  }

  return <UserAvatarMenu userProfile={userProfile} signOutUrl={signOutUrl} />;
};

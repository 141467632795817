'use client';

import { updateUser, updateUserProfile } from '@/actions';
import { toastTimeout, UserProfile as UserProfileData } from '@/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
  toast,
} from 'ui-lib';
import { z } from 'zod';

interface UserProfileEditFormProps {
  userProfile: UserProfileData;
}

const profileFormSchema = z.object({
  firstName: z
    .string()
    .min(2, {
      message: 'First Name must be at least 2 characters.',
    })
    .max(120, {
      message: 'First Name must not be longer than 120 characters.',
    }),
  lastName: z
    .string()
    .min(2, {
      message: 'Last Name must be at least 2 characters.',
    })
    .max(120, {
      message: 'Last Name must not be longer than 120 characters.',
    }),
  bio: z.string().max(400).min(4),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export const UserProfileEditForm: FC<UserProfileEditFormProps> = ({ userProfile }) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const router = useRouter();

  const onSubmit = async (data: ProfileFormValues) => {
    setIsLoading(true);

    await updateUser(userProfile.userId ?? '', {
      firstName: data.firstName,
      lastName: data.lastName,
    });

    await updateUserProfile(userProfile.userId ?? '', {
      bio: data.bio,
    });

    try {
      const updateUserRes = await updateUser(userProfile.userId ?? '', {
        firstName: data.firstName,
        lastName: data.lastName,
      });

      if (updateUserRes.error) {
        throw updateUserRes.error;
      }

      const updateUserProfileRes = await updateUserProfile(userProfile.userId ?? '', {
        bio: data.bio,
      });

      if (updateUserProfileRes.error) {
        throw updateUserProfileRes.error;
      }

      router.refresh();

      toast.success(`User Profile Updated`, {
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
  };

  const defaultValues: Partial<ProfileFormValues> = {
    firstName: userProfile.user?.firstName ?? '',
    lastName: userProfile.user?.lastName ?? '',
    bio: userProfile.bio ?? '',
  };

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
        <FormField
          control={form.control}
          name='firstName'
          render={({ field }) => (
            <FormItem>
              <FormLabel>First name</FormLabel>
              <FormControl>
                <Input placeholder='Enter your first name' {...field} />
              </FormControl>
              <FormDescription>This is the first name other users will see.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='lastName'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last name</FormLabel>
              <FormControl>
                <Input placeholder='Enter your last name' {...field} />
              </FormControl>
              <FormDescription>This is the last name other users will see.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='bio'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Bio</FormLabel>
              <FormControl>
                <Textarea placeholder='Tell us a little bit about yourself' {...field} />
              </FormControl>
              <FormDescription>A short biography other users will see.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex w-full flex-row items-center justify-end'>
          <Button type='submit' disabled={isLoading}>
            Update profile
          </Button>
        </div>
      </form>
    </Form>
  );
};

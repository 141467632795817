'use client';

import { Message } from '@/common';
import { FC } from 'react';
import { Separator } from 'ui-lib';
import { EnquiryMessageHeader } from './EnquiryMessageHeader';

interface EnquiryMessageCardProps {
  message: Message;
  showContextMenu?: boolean;
}

export const EnquiryMessageCard: FC<EnquiryMessageCardProps> = ({ message, showContextMenu = true }) => {
  const userProfile = message.createdByUserProfile;

  if (!userProfile) {
    return null;
  }

  return (
    <div className='bg-card text-card-foreground py-2 shadow-none'>
      <Separator />
      <EnquiryMessageHeader
        className='py-4'
        userProfile={userProfile}
        createdAt={message.createdAt}
        createdById={message.createdById}
        showContextMenu={showContextMenu}
      />
      <p>{message.body}</p>
    </div>
  );
};

import { Separator, XIcon, LinkedInIcon, YoutubeIcon } from 'ui-lib';
import { FooterLinks } from './FooterLinks';
import Link from 'next/link';

export const Footer = () => {
  return (
    <footer className='h-60 w-full'>
      <FooterLinks />
      <div className='text-secondary-foreground container w-full px-10 py-4'>
        <Separator orientation='horizontal' />
        <div className='flex flex-col items-center justify-between sm:flex-row'>
          <div className='p-4 text-sm'>
            <p>© 2023 MatterHive Ltd. All Rights Reserved.</p>
            {/* <p>MatterHive Ltd is registered in the UK with Company number 0000 (VAT NO: 0000) </p>
            <p>Registered Office: MatterHive</p> */}
          </div>
          <div className='flex flex-row gap-2 pr-4'>
            <Link href='https://www.linkedin.com/matterhive/'>
              <LinkedInIcon className='text-secondary-foreground' />
            </Link>
            <Link href='https://www.twitter.com/MatterHiveUK'>
              <XIcon className='text-secondary-foreground' />
            </Link>
            <Link href='https://www.youtube.com/@MatterHive'>
              <YoutubeIcon className='text-secondary-foreground' />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

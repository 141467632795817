import { Logo } from 'ui-lib';
import Link from 'next/link';

export function AuthNavBar() {
  return (
    <div className='flex flex-col'>
      <div className='border-b'>
        <div className='container flex h-16 items-center justify-start px-6'>
          <div className='flex h-full items-center p-4 pl-2'>
            <div className='h-full hover:opacity-90'>
              <Link href='/'>
                <Logo />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

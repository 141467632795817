import { PlatformNavLinks } from '@/components/Navbar';
import { HomeIcon, Separator } from 'ui-lib';
import { SidebarSection, SidebarSectionLinks } from '../SidebarSectionLinks';
import type { Route } from 'next';

const sidebarSections: SidebarSection[] = [
  {
    section: 'Dashboard',
    links: [
      {
        href: `/dashboard` satisfies Route<string>,
        label: 'Dashboard',
        icon: <HomeIcon />,
      },
    ],
  },
];

export const DashboardSidebarContent = () => {
  return (
    <div className={'pb-12 pr-4'}>
      <div className='pt-6 md:hidden md:py-0'>
        <h2 className='mb-2 pr-4 text-lg font-semibold tracking-tight'>Navigation</h2>
        <div className='ml-2'>
          <PlatformNavLinks />
        </div>
        <Separator className='mt-6' />
      </div>

      <div className='space-y-4 sm:py-6'>
        <SidebarSectionLinks sidebarSections={sidebarSections} />
      </div>
    </div>
  );
};

import { StepTypeEnum } from '@/common';

export const stepTypeToText = (stepType: StepTypeEnum): string => {
  if (stepType === StepTypeEnum.DocumentUpload) {
    return 'Document Upload';
  }

  if (stepType === StepTypeEnum.Enquiry) {
    return 'Enquiry';
  }

  if (stepType === StepTypeEnum.Form) {
    return 'Form';
  }

  if (stepType === StepTypeEnum.Task) {
    return 'Task';
  }

  return stepType;
};

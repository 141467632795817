'use client';

import { FC } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'ui-lib';
import { EnquiryCreateForm } from './EnquiryCreateForm';
import { ResourceEnum } from '@/common';

type EnquiryCreateCardDocumentProps = {
  type: ResourceEnum.Document;
  matterId: string;
  documentId: string;
  canCreateEnquiries: boolean;
};

type EnquiryCreateCardMatterStepProps = {
  type: ResourceEnum.MatterStep;
  matterId: string;
  matterStepId: string;
  canCreateEnquiries: boolean;
};

type EnquiryCreateCardProps = EnquiryCreateCardDocumentProps | EnquiryCreateCardMatterStepProps;

export const EnquiryCreateCard: FC<EnquiryCreateCardProps> = ({ matterId, canCreateEnquiries, ...props }) => {
  let entityName = '';

  if (props.type === ResourceEnum.Document) {
    entityName = 'document';
  }

  if (props.type === ResourceEnum.MatterStep) {
    entityName = 'step';
  }

  return (
    <Card className='w-full'>
      <CardHeader>
        <CardTitle>New Enquiry</CardTitle>
        <CardDescription>Raise a new enquiry about this {entityName}</CardDescription>
      </CardHeader>
      <CardContent>
        <EnquiryCreateForm canCreateEnquiries={canCreateEnquiries} matterId={matterId} {...props} />
      </CardContent>
    </Card>
  );
};

import { UserProfile as UserProfileData } from '@/common';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import {
  Button,
  Card,
  Pencil2Icon,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Typography,
  cn,
} from 'ui-lib';

interface UserProfileCardProps {
  userProfile: UserProfileData;
  canEdit: boolean;
  className?: string;
  showBio?: boolean;
  matterRole?: string;
}

export const UserProfileCard: FC<UserProfileCardProps> = ({
  userProfile,
  canEdit,
  className,
  showBio = true,
  matterRole,
}) => {
  const userInitials = `${userProfile?.user?.firstName?.charAt(0)?.toUpperCase() ?? ''}${userProfile?.user?.lastName?.charAt(0).toUpperCase() ?? ''}`;

  return (
    <Card className={cn('relative col-span-2', className)}>
      {canEdit ? (
        <div className='absolute right-0 top-0 flex flex-row items-start justify-end'>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button variant={'ghost'} size={'tight'} asChild>
                  <Link href={`/dashboard/users/${userProfile.userId}/manage-profile`}>
                    <Pencil2Icon className='h-6 w-auto' />
                  </Link>
                </Button>
              </TooltipTrigger>
              <TooltipContent>Edit profile content</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ) : null}
      <div className='grid grid-cols-1 gap-6 p-4 md:grid-cols-3'>
        <div className='relative col-span-1 flex flex-col items-center'>
          <Image
            src={
              userProfile.userAvatar?.resizedDownloadUrl ??
              userProfile.userAvatar?.downloadUrl ??
              `https://placehold.co/400.png?text=${userInitials}`
            }
            alt={`profile avatar`}
            width={200}
            height={200}
            className='border-muted-foreground h-auto rounded-md border object-contain md:w-full'
          />
        </div>
        <div className='col-span-2 flex flex-col'>
          <Typography as='p' variant={'largeText'} className='pb-2'>
            {userProfile.user?.firstName} {userProfile.user?.lastName}
          </Typography>
          {/* //TODO: Possibly add organisation links to user profiles */}
          {/* <p className=''>
            <Typography as='link' variant={'mutedText'} className='text-primary hover:underline' asChild>
              <Link href={`/dashboard/organisations`}>Acme Inc (todo)</Link>
            </Typography>
          </p> */}
          {showBio ? (
            <p className=''>
              <Typography as='span' variant={'mutedText'}>
                {userProfile.bio}
              </Typography>
            </p>
          ) : null}

          {matterRole ? (
            <p className=''>
              <Typography as='span' variant={'smallText'}>
                {matterRole}
              </Typography>
            </p>
          ) : null}
        </div>
      </div>
    </Card>
  );
};

'use client';

import { UserProfile as UserProfileData } from '@/common';
import { FC, useContext } from 'react';
import { UserProfileContext } from '../UserProfileContext';
import { UserProfileCard } from './UserProfileCard';

interface UserProfileProps {
  userProfile: UserProfileData;
}

export const UserProfile: FC<UserProfileProps> = ({ userProfile }) => {
  const currentUserProfile = useContext(UserProfileContext);

  const canEdit = userProfile.userId === currentUserProfile?.userId;

  return (
    <div className='grid w-full grid-cols-2 pt-6 lg:grid-cols-3'>
      <UserProfileCard userProfile={userProfile} canEdit={canEdit} />
    </div>
  );
};

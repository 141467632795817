import { InviteRequestMeta, Matter, MatterPermissionsEnum } from '@/common';
import { FC } from 'react';
import { Alert, AlertDescription, AlertTitle, InfoCircledIcon, Typography } from 'ui-lib';
import { MatterInviteRequestCard } from './MatterInviteRequestCard';

export interface MatterInviteRequestsProps {
  matter: Matter;
  matterInviteRequests: InviteRequestMeta[];
  matterPermissions: MatterPermissionsEnum[];
  tourId?: string;
}

export const MatterInviteRequests: FC<MatterInviteRequestsProps> = ({
  matter,
  matterInviteRequests,
  matterPermissions,
  tourId,
}) => {
  const canManageInvites = matterPermissions.includes(MatterPermissionsEnum.CanManageInvites) ?? false;

  const matterIsOpen = !matter.closedAt;

  const noPendingInvites = (matterInviteRequests.length ?? 0) === 0;

  const noInvitesMessage = 'Your matter has no pending invite requests.';

  return (
    <>
      <Typography data-tour-id={tourId} as='h3' variant='h3' className='pt-6'>
        Invite Requests
      </Typography>
      <Typography variant='lead' as='p' className='text-lg'>
        Users who have requested access to this matter
      </Typography>
      <div className='flex flex-col gap-4 py-6'>
        {noPendingInvites ? (
          <Alert>
            <InfoCircledIcon className='h-4 w-4' />
            <AlertTitle>No Pending Invite Requests</AlertTitle>
            <AlertDescription>{noInvitesMessage}</AlertDescription>
          </Alert>
        ) : null}
        {matterInviteRequests.map((inviteRequest) => (
          <MatterInviteRequestCard
            key={`inviteRequest_card_${inviteRequest.inviteRequest.id}`}
            inviteRequestId={inviteRequest.inviteRequest.id ?? ''}
            userProfile={inviteRequest.requestedByUserProfile}
            canManageInvites={canManageInvites && matterIsOpen}
          />
        ))}
      </div>
    </>
  );
};

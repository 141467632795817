import { Organisation as OrganisationData } from '@/common';
import Link from 'next/link';
import { FC } from 'react';
import { Card, CardHeader, CardTitle, Typography } from 'ui-lib';

export interface OrganisationListProps {
  organisations: OrganisationData[];
}

export const OrganisationList: FC<OrganisationListProps> = ({ organisations }) => {
  if (!organisations.length) {
    return (
      <div className=''>
        <Typography variant='mutedText' as='p'>
          No organisations
        </Typography>
      </div>
    );
  }

  return (
    <div className='grid w-full grid-cols-1 gap-6 py-6 sm:grid-cols-2'>
      {organisations.map((organisation) => (
        <Link key={`organisation_card_${organisation.id}`} href={`/dashboard/organisations/${organisation.id}`}>
          <Card className='hover:bg-secondary-foreground/5 col-span-1'>
            <CardHeader>
              <CardTitle>{organisation.name}</CardTitle>
            </CardHeader>
          </Card>
        </Link>
      ))}
    </div>
  );
};

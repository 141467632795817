import { InviteMeta } from '@/common';
import { FC } from 'react';
import { Alert, AlertDescription, AlertTitle, InfoCircledIcon, Typography } from 'ui-lib';
import { OrganisationInviteCard } from './OrganisationInviteCard';
import { OrganisationUserInviteCard } from './OrganisationUserInviteCard';

export interface OrganisationInvitesProps {
  organisationId: string;
  organisationInvites: InviteMeta[];
  canManageInvites: boolean;
  tourId?: string;
}

export const OrganisationInvites: FC<OrganisationInvitesProps> = ({
  organisationId,
  organisationInvites,
  canManageInvites,
  tourId,
}) => {
  const noPendingInvites = (organisationInvites.length ?? 0) === 0;

  const noInvitesMessage = canManageInvites
    ? 'You can invite users to your organisation below.'
    : 'Your organisation has no pending invitations.';

  return (
    <>
      <Typography data-tour-id={tourId} as='h3' variant='h3' className='pt-12'>
        Invited Members
      </Typography>
      <Typography variant='lead' as='p' className='text-lg'>
        Users who have been invited to this organisation
      </Typography>
      <div className='flex flex-col gap-4 py-6'>
        {noPendingInvites ? (
          <Alert>
            <InfoCircledIcon className='h-4 w-4' />
            <AlertTitle>No Pending Invites</AlertTitle>
            <AlertDescription>{noInvitesMessage}</AlertDescription>
          </Alert>
        ) : null}
        {organisationInvites.map((invite) => (
          <OrganisationInviteCard
            key={`invite_card_${invite.invite.id}`}
            inviteId={invite.invite.id ?? ''}
            user={invite.invitedUser}
            canManageInvites={canManageInvites}
          />
        ))}
        {canManageInvites ? <OrganisationUserInviteCard organisationId={organisationId} /> : null}
      </div>
    </>
  );
};

'use client';

import { Enquiry, EnquiryStatusEnum, toastTimeout } from '@/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
  toast,
} from 'ui-lib';
import { z } from 'zod';
import { updateEnquiryStatus } from '@/actions';

interface EnquiryUpdateStatusDialogProps {
  enquiry: Enquiry;
}

const updateEnquiryFormSchema = z.object({
  status: z.nativeEnum(EnquiryStatusEnum),
});

type UpdateEnquiryFormValues = z.infer<typeof updateEnquiryFormSchema>;

const statusToText = (status: EnquiryStatusEnum): string => {
  if (status === EnquiryStatusEnum.Open) {
    return 'Open';
  }

  if (status === EnquiryStatusEnum.InReview) {
    return 'In Review';
  }

  if (status === EnquiryStatusEnum.Resolved) {
    return 'Resolved';
  }

  return status;
};

export const EnquiryUpdateStatusDialog: FC<EnquiryUpdateStatusDialogProps> = ({ enquiry }) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const defaultValues: Partial<UpdateEnquiryFormValues> = {
    status: enquiry.status,
  };

  const form = useForm<UpdateEnquiryFormValues>({
    resolver: zodResolver(updateEnquiryFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  const onSubmit = async (data: UpdateEnquiryFormValues) => {
    setIsLoading(true);

    try {
      const res = await updateEnquiryStatus(enquiry.id ?? '', {
        status: data.status,
      });

      if (res?.error) {
        throw res.error;
      }

      setDialogOpen(false);
      router.refresh();

      toast.success(`Status updated`, {
        description: `${statusToText(data.status)}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
  };

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Typography variant='smallText' className='cursor-pointer underline'>
          update status
        </Typography>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='w-full space-y-6'>
            <DialogHeader>
              <DialogTitle>Update enquiry status</DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name='status'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Status</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder='Select a status' />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={EnquiryStatusEnum.Open}>{statusToText(EnquiryStatusEnum.Open)}</SelectItem>
                      <SelectItem value={EnquiryStatusEnum.InReview}>
                        {statusToText(EnquiryStatusEnum.InReview)}
                      </SelectItem>
                      <SelectItem value={EnquiryStatusEnum.Resolved}>
                        {statusToText(EnquiryStatusEnum.Resolved)}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button type='submit' disabled={isLoading}>
                Save changes
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

import {
  MatterOverview,
  adjustUTCDateToLocalTimezone,
  getFormattedDateAndTimeString,
  getRelativeTimeString,
} from '@/common';
import Link from 'next/link';
import { FC } from 'react';
import { Badge, Progress, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, Typography, cn } from 'ui-lib';
import { Address } from '../Address';
import { getMatterBadgeVariant, getMatterStatusText } from './utils';

type MatterOverviewCardProps = {
  matterOverview: MatterOverview;
  type: 'overview' | 'view';
  tourId?: string;
};

export const MatterOverviewCard: FC<MatterOverviewCardProps> = ({ matterOverview, type, tourId }) => {
  const matter = matterOverview.matter;

  if (!matter) {
    return null;
  }

  const statusText = getMatterStatusText(matter);
  const badgeVariant = getMatterBadgeVariant(matter);

  const adjustedCreatedDate = adjustUTCDateToLocalTimezone(
    new Date(matterOverview.matter?.createdAt as unknown as string),
  );
  const formattedCreatedDateTime = getFormattedDateAndTimeString(adjustedCreatedDate);
  const relativeCreatedDate = getRelativeTimeString(adjustedCreatedDate);

  return (
    <div data-tour-id={tourId} className='bg-card text-card-foreground w-full rounded-md border px-4 py-2 pt-4 shadow'>
      <div
        className={cn(
          'xs:flex-row xs:items-start flex flex-col-reverse items-start justify-between gap-4 space-x-2 pb-2',
        )}
      >
        <div className='flex h-full w-full flex-col items-start justify-between'>
          <div className={cn('flex w-full flex-col items-start justify-start')}>
            <Typography variant='h4'>{matter.name}</Typography>
            <Address className='pt-2' address={matter.matterDetail?.propertyInfo?.address} />
          </div>

          {type === 'overview' ? (
            <div className='flex w-full flex-col items-start justify-stretch gap-y-2 pt-2'>
              <Typography variant='smallText'>{matterOverview.percentProgress ?? 0}% complete</Typography>
              <Progress value={matterOverview.percentProgress ?? 0} />
            </div>
          ) : null}
        </div>

        <div className={cn('flex flex-shrink-0 flex-col items-end justify-start')}>
          <Badge className='flex w-full items-center justify-center text-sm' variant={badgeVariant}>
            {statusText}
          </Badge>
          <div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Typography variant='mutedText' className='text-xs'>
                    Created: {relativeCreatedDate}
                  </Typography>
                </TooltipTrigger>
                <TooltipContent>{formattedCreatedDateTime}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <br />
            <Typography variant='mutedText' className='text-xs'>
              Members: {matterOverview.memberCount}
            </Typography>
            <Typography variant='mutedText' className='text-xs'>
              Open Steps: {matterOverview.openSteps}
            </Typography>
            <Typography variant='mutedText' className='text-xs'>
              In Review Steps: {matterOverview.inReviewSteps}
            </Typography>
          </div>
        </div>
      </div>

      {type === 'overview' ? (
        <div className='flex items-center justify-end gap-x-2'>
          <Link className='font-medium underline-offset-4 hover:underline' href={`/dashboard/matters/${matter.id}`}>
            view
          </Link>
        </div>
      ) : null}
    </div>
  );
};

import { FC } from 'react';
import { PlatformNavBar } from '../Navbar';
import { DesktopSidebar } from '../Sidebar/DesktopSidebar';
import { MobileSidebar } from '../Sidebar/MobileSidebar';

interface PlatformLayoutProps {
  children: React.ReactNode;
  sidebarContent: JSX.Element;
}

export const PlatformLayout: FC<PlatformLayoutProps> = ({ children, sidebarContent }) => {
  return (
    <>
      <header className='antialiased'>
        <PlatformNavBar>
          <MobileSidebar>{sidebarContent}</MobileSidebar>
        </PlatformNavBar>
      </header>
      <main className='min-h-[90svh] w-full'>
        <div className='container min-h-screen flex-1 items-start md:grid md:grid-cols-[220px_minmax(0,1fr)] lg:grid-cols-[240px_minmax(0,1fr)]'>
          <DesktopSidebar>{sidebarContent}</DesktopSidebar>
          {children}
        </div>
      </main>
    </>
  );
};

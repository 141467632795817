'use client';

import { Message, MessageTypeEnum } from '@/common';
import { FC, useContext } from 'react';
import { Button, FileIcon, Separator, Typography, cn } from 'ui-lib';
import { EnquiryMessageHeader } from './EnquiryMessageHeader';
import { UserProfileContext } from '../UserProfileContext';
import Link from 'next/link';

interface EnquiryChatMessageBubbleProps {
  message: Message;
  matterId: string;
  showMeta: boolean;
  first: boolean;
  last: boolean;
}

export const EnquiryChatMessageBubble: FC<EnquiryChatMessageBubbleProps> = ({
  message,
  matterId,
  showMeta = true,
  first = true,
  last = true,
}) => {
  const userProfile = message.createdByUserProfile;
  const currentUserProfile = useContext(UserProfileContext);

  if (!userProfile) {
    return null;
  }

  const messageFromCurrentUser = userProfile.id === currentUserProfile?.id;

  return (
    <div
      className={cn('mb-1 flex w-full items-center px-4', {
        'justify-start': !messageFromCurrentUser,
        'justify-end': messageFromCurrentUser,
      })}
    >
      <div
        className={cn('flex max-w-[80%] flex-col', {
          'items-end': messageFromCurrentUser,
        })}
      >
        {showMeta ? (
          <EnquiryMessageHeader
            className={cn('py-0 pb-2 pt-2', {
              'flex-row-reverse': messageFromCurrentUser,
            })}
            userProfile={userProfile}
            createdAt={message.createdAt}
            createdById={message.createdById}
            showContextMenu={false}
            dateStyle='medium'
            timeStyle='short'
          />
        ) : null}
        <div
          className={cn('bg-card text-card-foreground whitespace-pre-line px-4 py-2 drop-shadow-sm', {
            'rounded-l-md': messageFromCurrentUser,
            'rounded-tr-md': first && messageFromCurrentUser,
            'rounded-br-md': last && messageFromCurrentUser,
            'rounded-r-md': !messageFromCurrentUser,
            'rounded-tl-md': first && !messageFromCurrentUser,
            'rounded-bl-md': last && !messageFromCurrentUser,
            'space-y-2': message.type === MessageTypeEnum.Document,
          })}
        >
          {message.type === MessageTypeEnum.Message ? message.body : null}

          {message.type === MessageTypeEnum.Document &&
            message?.documents?.map?.((document, idx) => (
              <>
                {idx > 0 ? <Separator className='w-full' /> : null}
                <div className='flex h-full w-full flex-row items-center justify-between space-x-4'>
                  <div className='flex h-full w-full flex-row items-center justify-start space-x-4'>
                    <FileIcon width={24} height={24} />
                    <Typography variant='smallText'>{document.name}</Typography>
                  </div>
                  <Button asChild variant='outline'>
                    <Link href={`/dashboard/matters/${matterId}/documents/${document.id}`}>View</Link>
                  </Button>
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

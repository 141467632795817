'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input, Textarea, toast } from 'ui-lib';
import { z } from 'zod';
import { createEnquiry } from '@/actions';
import { ResourceEnum, toastTimeout } from '@/common';

type EnquiryCreateFormMatterProps = {
  type: ResourceEnum.Matter;
  matterId: string;
  canCreateEnquiries: boolean;
  shouldRedirect?: boolean;
};

type EnquiryCreateFormDocumentProps = {
  type: ResourceEnum.Document;
  matterId: string;
  documentId: string;
  canCreateEnquiries: boolean;
  shouldRedirect?: boolean;
};

type EnquiryCreateFormMatterStepProps = {
  type: ResourceEnum.MatterStep;
  matterId: string;
  matterStepId: string;
  canCreateEnquiries: boolean;
  shouldRedirect?: boolean;
};

type EnquiryCreateFormProps =
  | EnquiryCreateFormMatterProps
  | EnquiryCreateFormDocumentProps
  | EnquiryCreateFormMatterStepProps;

const createEnquiryFormSchema = z.object({
  title: z
    .string()
    .min(2, {
      message: 'Title must be at least 2 characters.',
    })
    .max(100, {
      message: 'Title must not be longer than 100 characters.',
    }),
  body: z
    .string()
    .min(2, {
      message: 'Body must be at least 2 characters.',
    })
    .max(300, {
      message: 'Body must not be longer than 300 characters.',
    }),
});

type CreateEnquiryFormValues = z.infer<typeof createEnquiryFormSchema>;

export const EnquiryCreateForm: FC<EnquiryCreateFormProps> = ({
  matterId,
  canCreateEnquiries,
  shouldRedirect = false,
  ...props
}) => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState<boolean>();

  const defaultValues: Partial<CreateEnquiryFormValues> = {
    title: '',
    body: '',
  };

  const form = useForm<CreateEnquiryFormValues>({
    resolver: zodResolver(createEnquiryFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  const onSubmit = async (data: CreateEnquiryFormValues) => {
    setIsLoading(true);

    try {
      const res = await createEnquiry(
        {
          title: data.title,
          body: data.body,
          matterId,
          ...(props.type === ResourceEnum.Document ? { documentId: props.documentId } : {}),
          ...(props.type === ResourceEnum.MatterStep ? { matterStepId: props.matterStepId } : {}),
        },
        shouldRedirect,
      );

      if (res?.error) {
        throw res.error;
      }

      form.reset();

      toast.success(`Enquiry Created`, {
        description: `${data.title} created`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });

      router.refresh();
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
  };

  if (!canCreateEnquiries) {
    return null;
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-2'>
        <FormField
          control={form.control}
          name='title'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder='Title of the enquiry' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='body'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Body</FormLabel>
              <FormControl>
                <Textarea placeholder='Your enquiry...' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex flex-row justify-end'>
          <Button type='submit' disabled={isLoading}>
            Create
          </Button>
        </div>
      </form>
    </Form>
  );
};

import { MatterRoleEnum, OrganisationRoleEnum, ResourceEnum, UserProfile, getUserFullName } from '@/common';
import { RoleSelectRole } from '@/common/types';
import { Card } from 'ui-lib';
import { UserAvatar } from '../UserAvatar';
import { MemberRoleSelect } from './MemberRoleSelect';

type MatterMemberCardProps = {
  type: ResourceEnum.Matter;
  matterId: string;
  roleSelects: RoleSelectRole<MatterRoleEnum>[];
  userProfile?: UserProfile;
  currentRole: MatterRoleEnum;
  canEdit: boolean;
};

type OrganisationMemberCardProps = {
  type: ResourceEnum.Organisation;
  organisationId: string;
  roleSelects: RoleSelectRole<OrganisationRoleEnum>[];
  userProfile?: UserProfile;
  currentRole: OrganisationRoleEnum;
  canEdit: boolean;
};

type MemberCardProps = MatterMemberCardProps | OrganisationMemberCardProps;

export const MemberCard = ({ userProfile, canEdit, ...props }: MemberCardProps) => {
  if (!userProfile) {
    return null;
  }

  const userId = userProfile.userId;

  const fullName = getUserFullName(userProfile.user);

  return (
    <Card className='xs:flex xs:flex-row xs:items-center xs:justify-between xs:space-x-6 grid grid-cols-1 p-4'>
      <div className='flex items-center justify-start'>
        <UserAvatar userProfile={userProfile} />
        <div className='flex flex-col pl-6'>
          <p className='text-sm font-medium leading-none'>{fullName}</p>
          <p className='text-muted-foreground text-sm'>{userProfile.user?.email}</p>
        </div>
      </div>

      <MemberRoleSelect userId={userId} fullName={fullName} canEdit={canEdit} {...props} />
    </Card>
  );
};

'use client';

import { Matter } from '@/common';
import { useParams, useRouter } from 'next/navigation';
import { FC, useState } from 'react';

import {
  cn,
  comboboxLabelFilter,
  CaretSortIcon,
  CheckIcon,
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  ScrollArea,
} from 'ui-lib';

export type MatterComboboxProps = {
  matterData: Matter[];
};

export const MatterCombobox: FC<MatterComboboxProps> = ({ matterData }) => {
  const [open, setOpen] = useState<boolean>(false);

  const params = useParams<{ matterId: string }>();
  const { push } = useRouter();

  const activeMatterId = params?.matterId;

  const changeSelectedMatter = (selectedMatterId: string) => {
    push(`/dashboard/matters/${selectedMatterId}`);
  };

  const matters: { matterId: string; label: string }[] =
    matterData?.map?.(({ id, name }) => ({ matterId: id ?? '', label: name ?? '' })) ?? [];

  const activeMatterLabel = matters.find(({ matterId }) => matterId === activeMatterId)?.label;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          data-tour-id='matters-sidebar-combo-box'
          variant='outline'
          role='combobox'
          aria-expanded={open}
          className='w-full justify-between'
        >
          <span className='truncate'>
            {activeMatterId && activeMatterLabel ? activeMatterLabel : 'Select matter...'}
          </span>
          <CaretSortIcon className='ml-2 h-4 w-4 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>

      <PopoverContent className='w-full max-w-80 p-0' align='start'>
        <Command filter={comboboxLabelFilter(matters, 'matterId')}>
          <ScrollArea className='max-h-80 overflow-y-scroll'>
            <CommandInput placeholder='Search matter...' className='h-9' />
            <CommandEmpty>No matter found.</CommandEmpty>

            <CommandGroup>
              {matters.map(({ matterId, label }) => (
                <HoverCard key={`combo_matter_hover_${matterId}`}>
                  <HoverCardTrigger asChild>
                    <CommandItem
                      key={matterId}
                      className='flex w-full justify-start'
                      value={matterId}
                      onSelect={(selectedMatterId: string) => {
                        changeSelectedMatter(selectedMatterId);
                        setOpen(false);
                      }}
                    >
                      <span className='truncate'>{label}</span>
                      <CheckIcon
                        className={cn('ml-auto h-4 w-4', activeMatterId === matterId ? 'opacity-100' : 'opacity-0')}
                      />
                    </CommandItem>
                  </HoverCardTrigger>
                  <HoverCardContent className='max-w-60' align='start' side='right'>
                    <div className='flex justify-start'>{label}</div>
                  </HoverCardContent>
                </HoverCard>
              ))}
            </CommandGroup>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

'use client';

import { Step } from '@/common';
import { FC, useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, Typography } from 'ui-lib';
import { StepForm } from './StepForm';

interface StepEditModalProps {
  step: Step;
  canEditStep: boolean;
}

export const StepEditModal: FC<StepEditModalProps> = ({ step, canEditStep }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  if (!canEditStep) {
    return null;
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Typography className='cursor-pointer font-medium underline-offset-4 hover:underline [&:not(:first-child)]:mt-0'>
          edit
        </Typography>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Step</DialogTitle>
          <DialogDescription>Edit your workflow step</DialogDescription>
        </DialogHeader>
        <StepForm
          type='edit'
          step={step}
          stepId={step.id ?? ''}
          hasPermission={canEditStep}
          onSuccess={() => {
            setDialogOpen(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

'use client';

import { FC } from 'react';
import { Button, Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from 'ui-lib';
import { EnquiryCreateForm } from './EnquiryCreateForm';
import { ResourceEnum } from '@/common';

interface EnquiryCreateModalProps {
  matterId: string;
  canCreateEnquiries: boolean;
}

export const EnquiryCreateModal: FC<EnquiryCreateModalProps> = ({ matterId, canCreateEnquiries }) => {
  if (!canCreateEnquiries) {
    return null;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button data-tour-id='enquiries-create-button'>New Enquiry</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Enquiry</DialogTitle>
          <DialogDescription>Raise a new enquiry on this matter</DialogDescription>
        </DialogHeader>
        <EnquiryCreateForm
          type={ResourceEnum.Matter}
          matterId={matterId}
          canCreateEnquiries={canCreateEnquiries}
          shouldRedirect={true}
        />
      </DialogContent>
    </Dialog>
  );
};

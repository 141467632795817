'use client';

import { Route } from 'next';
import Link, { LinkProps } from 'next/link';
import { FC, useEffect, useState } from 'react';
import { Button, ChevronDownIcon, ChevronUpIcon, Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui-lib';
import { SidebarLinkButton } from './SidebarSectionLinks';
import { usePathname } from 'next/navigation';

export type SidebarLinkProps = Pick<LinkProps<Route>, 'href'> & {
  children: React.ReactNode;
  subLinks?: SidebarLinkButton[];
  tourId?: string;
};

export const SidebarLink: FC<SidebarLinkProps> = ({ children, href, subLinks, tourId }) => {
  const [collapseOpen, setCollapseOpen] = useState<boolean>(true);

  const pathname = usePathname();

  const selected = pathname === href;

  const hasSublinks = subLinks && subLinks.length > 0;

  useEffect(() => {
    if (!hasSublinks) {
      return;
    }

    const subLinkIsActive = subLinks.some(({ href }) => href === pathname);

    if (subLinkIsActive) {
      setCollapseOpen(true);
    }
  }, [pathname, subLinks, hasSublinks, setCollapseOpen]);

  if (!hasSublinks) {
    return (
      <Button variant={selected ? 'secondary' : 'ghost'} className='w-full justify-start' asChild>
        <Link data-tour-id={tourId} href={href}>
          {children}
        </Link>
      </Button>
    );
  }

  return (
    <Collapsible open={collapseOpen} onOpenChange={setCollapseOpen} className='flex w-full flex-col space-y-1'>
      <div className='flex w-full flex-row items-center justify-between'>
        <Button variant={selected ? 'secondary' : 'ghost'} className='w-full justify-start rounded-r-none' asChild>
          <Link data-tour-id={tourId} href={href}>
            {children}
          </Link>
        </Button>
        <CollapsibleTrigger asChild>
          <Button variant={selected ? 'secondary' : 'ghost'} size='icon' className='rounded-l-none rounded-r-md'>
            {collapseOpen ? <ChevronUpIcon /> : null}
            {!collapseOpen ? <ChevronDownIcon /> : null}
          </Button>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent className='flex w-full flex-col space-y-1 pl-4'>
        {subLinks.map(({ href, label, icon, tourId }) => (
          <Button
            key={`subLink_${label}${href}`}
            variant={pathname === href ? 'secondary' : 'ghost'}
            className='w-full justify-start'
            asChild
          >
            <Link data-tour-id={tourId} href={href}>
              {icon}
              <span className='ml-4'>{label}</span>
            </Link>
          </Button>
        ))}
      </CollapsibleContent>
    </Collapsible>
  );
};

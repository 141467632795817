import { Button, Logo } from 'ui-lib';
import Link from 'next/link';

export function LandingNavBar() {
  return (
    <div className='flex flex-col'>
      <div className='border-b'>
        <div className='container flex h-16 items-center justify-start px-6'>
          <div className='flex h-full items-center p-4 pl-2'>
            <div className='h-full hover:opacity-90'>
              <Link href='/'>
                <Logo />
              </Link>
            </div>
          </div>
          <div className='ml-auto flex items-center space-x-4'>
            <Button variant='default' asChild>
              <Link href='/auth/sign-up'>Sign Up</Link>
            </Button>
            <Button variant='default' asChild>
              <Link href='/dashboard'>Sign In</Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

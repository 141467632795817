import { Step, StepTypeEnum } from '@/common';
import { FC } from 'react';
import { Typography } from 'ui-lib';
import { StepIcon } from './StepIcon';

export type StepTaskCardProps = {
  step: Step;
};

export const StepTaskCard: FC<StepTaskCardProps> = ({ step }) => {
  return (
    <div className='bg-card text-card-foreground grid h-full w-full grid-cols-1 rounded-md border px-4 py-2 shadow'>
      <div className='flex h-full w-full flex-row items-stretch justify-start'>
        <div className='flex h-full flex-shrink items-center justify-center pr-4'>
          <StepIcon stepType={step?.type ?? StepTypeEnum.Task} width={32} height={32} />
        </div>
        <div className='flex h-full w-full flex-col items-start justify-center'>
          <Typography variant='h4'>{step.name}</Typography>
          <Typography variant='mutedText' className='whitespace-pre-line'>
            {step.description}
          </Typography>
        </div>
      </div>
    </div>
  );
};

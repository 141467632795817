'use client';

import { closeOrganisation } from '@/actions';
import { Organisation, toastTimeout } from '@/common';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  toast,
} from 'ui-lib';

interface OrganisationCloseDialogProps {
  organisation: Organisation;
}

export const OrganisationCloseDialog: FC<OrganisationCloseDialogProps> = ({ organisation }) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const onClose = async () => {
    setIsLoading(true);

    try {
      const res = await closeOrganisation(organisation.id ?? '');

      if (res?.error) {
        throw res.error;
      }

      setDialogOpen(false);
      router.refresh();

      toast.success(`Organisation closed`, {
        description: `${organisation.name} closed`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
  };

  return (
    <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen} defaultOpen={false}>
      <AlertDialogTrigger asChild>
        <Button variant='outlineDestructive'>Close organisation</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action will close this organisation and prevent future changes
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoading}>Cancel</AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoading}
            onClick={() => {
              onClose();
            }}
          >
            Close
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

import { FC, ReactNode } from 'react';

export interface DesktopSidebarProps {
  children: ReactNode;
}

export const DesktopSidebar: FC<DesktopSidebarProps> = ({ children }) => {
  return (
    <aside className='top-14 z-30 hidden h-full min-h-[90svh] w-full shrink-0 border-r md:block'>
      <div className='h-full'>{children}</div>
    </aside>
  );
};

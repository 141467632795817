import { Matter, MatterMember, MatterPermissionsEnum, MatterRoleEnum, ResourceEnum } from '@/common';
import { FC } from 'react';
import { Typography } from 'ui-lib';
import { MemberCard } from '../Members';
import { RoleSelectRole } from '@/common/types';

const roleSelects: RoleSelectRole<MatterRoleEnum>[] = [
  {
    role: MatterRoleEnum.Owner,
    label: 'Owner',
    description: 'Owners can make changes to matters and members',
  },
  {
    role: MatterRoleEnum.Member,
    label: 'Member',
    description: 'Members have restricted access',
  },
];

export interface MatterMembersListProps {
  matter: Matter;
  matterMembers: MatterMember[];
  matterPermissions: MatterPermissionsEnum[];
  tourId?: string;
}

export const MatterMembersList: FC<MatterMembersListProps> = ({ matter, matterMembers, matterPermissions, tourId }) => {
  const canEdit = matterPermissions.includes(MatterPermissionsEnum.CanEdit);

  const matterIsOpen = !matter.closedAt;

  return (
    <>
      <Typography data-tour-id={tourId} as='h3' variant='h3' className='pt-6'>
        Active Members
      </Typography>
      <div className='flex flex-col gap-4 pt-6'>
        {matterMembers.map((member) => (
          <MemberCard
            key={`member_card_${member.userProfile?.userId}`}
            type={ResourceEnum.Matter}
            matterId={matter.id ?? ''}
            roleSelects={roleSelects}
            userProfile={member.userProfile}
            currentRole={member.role as MatterRoleEnum}
            canEdit={canEdit && matterIsOpen}
          />
        ))}
      </div>
    </>
  );
};

'use client';

import { createDocument, createMessage, getDocumentUpload } from '@/actions';
import { DocumentPost, MessageTypeEnum, toastTimeout, uploadWithProgress } from '@/common';
import prettyBytes from '@/common/utils/pretty-byte.utils';
import { zodResolver } from '@hookform/resolvers/zod';
import Link from 'next/link';
import { FC, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Cross2Icon,
  Dropzone,
  ExclamationTriangleIcon,
  FileTextIcon,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Progress,
  Separator,
  Typography,
  toast,
} from 'ui-lib';
import { z } from 'zod';

const createDocumentFormSchema = z.object({
  documents: z.array(
    z.object({
      file: z.instanceof(File),
      documentName: z
        .string()
        .min(2, { message: 'Document name must be at least 2 characters' })
        .max(200, { message: 'Document name must not be longer than 200 characters' }),
    }),
  ),
});

type CreateDocumentFormValues = z.infer<typeof createDocumentFormSchema>;

const FIFTY_MEGABYTES = 50000000;

export type DocumentUploadFormProps =
  | {
      type: 'matter';
      matterId: string;
      matterStepId?: string;
      formFieldId?: string;
      onComplete: () => void;
    }
  | {
      type: 'enquiry';
      matterId: string;
      enquiryId: string;
      onComplete: () => void;
    };

export const DocumentUploadForm: FC<DocumentUploadFormProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [uploadState, setUploadState] = useState<{ documentIdx: number; percent: number; error?: boolean }[]>([]);

  const defaultValues: Partial<CreateDocumentFormValues> = {
    documents: [],
  };

  const form = useForm<CreateDocumentFormValues>({
    resolver: zodResolver(createDocumentFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray({
    name: 'documents',
    control: form.control,
  });

  const canUpload = !isLoading && uploadState.length === 0 && fields.length > 0;

  const onSubmit = async (data: CreateDocumentFormValues) => {
    setIsLoading(true);

    setUploadState(() => data.documents?.map?.((_, documentIdx) => ({ documentIdx, percent: 0 })) ?? []);

    let messageId: string | undefined;

    if (props.type === 'enquiry') {
      const messageRes = await createMessage({
        enquiryId: props.enquiryId,
        body: '',
        type: MessageTypeEnum.Document,
      });

      if (messageRes.error) {
        toast.error('Error uploading document', {
          description: `${messageRes.error.message}`,
          dismissible: true,
          closeButton: true,
          duration: toastTimeout,
        });

        throw messageRes.error;
      }

      messageId = messageRes.data?.id;
    }

    for (const [documentIndex, documentData] of data.documents.entries()) {
      const newDocument: DocumentPost = {
        name: documentData.documentName,
        matterId: props.matterId,
        contentType: documentData.file.type,
        messageId,
        ...(props.type === 'matter' ? { matterStepId: props.matterStepId } : {}),
        ...(props.type === 'matter' ? { formFieldId: props.formFieldId } : {}),
      };

      if (uploadState[documentIndex]?.percent === 100) {
        continue;
      }

      try {
        const toastId = toast.loading(`Document uploading`, {
          description: `${newDocument.name}`,
          dismissible: false,
          closeButton: true,
          duration: toastTimeout,
        });

        const documentRes = await createDocument(newDocument);

        if (documentRes.error) {
          toast.error('Error uploading document', {
            id: toastId,
            description: `${newDocument.name} - ${documentRes.error.message}`,
            dismissible: true,
            closeButton: true,
            duration: toastTimeout,
          });
          throw documentRes.error;
        }

        const documentUploadRes = await getDocumentUpload(documentRes.data.id ?? '');

        if (documentUploadRes.error) {
          toast.error('Error uploading document', {
            id: toastId,
            description: `${newDocument.name} - ${documentUploadRes.error.message}`,
            dismissible: true,
            closeButton: true,
            duration: toastTimeout,
          });
          throw documentRes.error;
        }

        await uploadWithProgress(documentUploadRes.data.uploadUrl ?? '', documentData.file, (percentComplete) => {
          setUploadState((prev) => {
            if (prev[documentIndex]) {
              prev[documentIndex].percent = percentComplete;
            }

            return prev;
          });
        });

        toast.success('Document uploaded', {
          id: toastId,
          description: `${newDocument.name}`,
          dismissible: true,
          closeButton: true,
          duration: toastTimeout,
        });
      } catch (e) {
        console.trace(e);

        setUploadState((prev) => {
          if (prev[documentIndex]) {
            prev[documentIndex].error = true;
          }

          return prev;
        });
      }
    }

    setIsLoading(false);
    remove();

    if (!uploadState.some(({ error }) => error)) {
      if (props.type === 'matter') {
        window.scrollTo(0, 0);
      }

      props.onComplete();
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          // prevent modal form from submitting parent form also
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit(onSubmit)(e);
        }}
        className='space-y-8'
      >
        <FormField
          control={form.control}
          name='documents'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Upload Files</FormLabel>
              <FormControl>
                <Dropzone
                  dropzoneOptions={{
                    accept: { 'application/pdf': ['.pdf'] },
                    multiple: true,
                    maxSize: FIFTY_MEGABYTES,
                    onDrop: (files) => {
                      if (uploadState.length) {
                        remove(uploadState.map(({ documentIdx }) => documentIdx));

                        setUploadState([]);
                      }

                      for (const file of files) {
                        append({
                          documentName: file.name?.split?.('.').slice(0, -1).join('.') ?? '',
                          file,
                        });
                      }
                    },
                    onDropRejected: (rejections) => {
                      console.warn(rejections);
                      for (const rejection of rejections) {
                        toast.error(rejection.errors[0].message, {
                          description: `${rejection.file.name}`,
                          dismissible: true,
                          closeButton: true,
                          duration: toastTimeout,
                        });
                      }
                    },
                  }}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {fields.map((value, index) => (
          <div key={`document_field_${value?.file?.name}`} className=''>
            <Separator className='mb-6 px-6' />
            <div className='flex flex-row items-center justify-between'>
              <div className='flex flex-row items-center justify-start space-x-2 pb-2'>
                <FileTextIcon className='h-6 w-6' />
                <Typography variant='smallText' className='font-semibold'>
                  {value?.file?.name}
                </Typography>
                <Typography variant='mutedText'>
                  {prettyBytes(value?.file?.size, { locale: 'en-GB', maximumFractionDigits: 2 })}
                </Typography>
              </div>
              <div className=''>
                <Button
                  variant='destructive'
                  size='tight'
                  className='p-1'
                  disabled={isLoading}
                  onClick={() => {
                    remove(index);
                    setUploadState((prev) => prev.splice(index, 1));
                  }}
                >
                  <Cross2Icon className='' />
                </Button>
              </div>
            </div>
            <FormField
              control={form.control}
              name={`documents.${index}.documentName`}
              disabled={isLoading || uploadState?.[index]?.percent === 100}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Document Name</FormLabel>
                  <FormControl>
                    <Input placeholder='The name of the document' {...field} />
                  </FormControl>
                  <FormDescription>A recognisable name for the document</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            {uploadState?.[index] ? (
              <div className='h-12 w-full py-2'>
                <Typography variant='mutedText'>Upload Progress {uploadState[index].percent}%</Typography>
                <Progress value={uploadState[index].percent} />
              </div>
            ) : null}
            {uploadState?.[index]?.error ? (
              <div className='h-12 w-full py-2'>
                <Alert variant='destructive'>
                  <ExclamationTriangleIcon className='h-4 w-4' />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>
                    Upload for {value?.file?.name ?? 'file'} failed!
                    <br />
                    Please try again or&nbsp;
                    <Link href='https://www.matterhive.co.uk/support' className='underline'>
                      contact support.
                    </Link>
                  </AlertDescription>
                </Alert>
              </div>
            ) : null}
          </div>
        ))}

        <div className='flex flex-row justify-end'>
          <Button type='submit' disabled={!canUpload}>
            Add Document{fields?.length > 1 ? 's' : ''}
          </Button>
        </div>
      </form>
    </Form>
  );
};

import { NotificationsDropdown } from '@/components';
import Link from 'next/link';
import { Logo } from 'ui-lib';
import { NavAuth } from './NavAuth';

import { PlatformNavLinks } from './PlatformNavLinks';

interface PlatformNavBarProps {
  children: React.ReactNode;
}

export function PlatformNavBar({ children }: PlatformNavBarProps) {
  return (
    <div className='flex flex-col md:flex'>
      <div className='border-b'>
        <div className='container flex h-16 items-center justify-start px-6'>
          {children}
          <div className='flex h-full items-center p-4 pl-2'>
            <div className='h-full hover:opacity-90'>
              <Link href='/dashboard'>
                <Logo />
              </Link>
            </div>
          </div>
          <PlatformNavLinks className='hidden md:block' />
          <div className='ml-auto flex items-center space-x-4'>
            <NotificationsDropdown />
            <NavAuth forceLogin={true} />
          </div>
        </div>
      </div>
    </div>
  );
}

'use client';

import {
  NotificationActionEnum,
  NotificationMeta,
  ResourceEnum,
  adjustUTCDateToLocalTimezone,
  getFormattedDateAndTimeString,
  getRelativeTimeString,
  getUserFullName,
  notificationsTag,
  toastTimeout,
} from '@/common';
import { Route } from 'next';
import Link from 'next/link';
import { FC, Ref, useContext, useState } from 'react';
import {
  Alert,
  AlertDescription,
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Typography,
  toast,
} from 'ui-lib';
import { UserAvatar } from '../../UserAvatar';
import { UserProfileContext } from '../../UserProfileContext';
import { dismissNotification } from '@/actions';
import { useRouter } from 'next/navigation';
import { useSWRConfig } from 'swr';

export interface NotificationProps {
  notification: NotificationMeta;
  focusRef?: Ref<any>;
  tourId?: string;
}

const getEntityLink = (notification: NotificationMeta): Route<any> => {
  if (notification.notificationResource === ResourceEnum.Organisation) {
    const route = `/dashboard/organisations/${notification.organisationId}` as const;

    return route satisfies Route<typeof route>;
  }

  if (notification.notificationResource === ResourceEnum.Matter) {
    const route = `/dashboard/matters/${notification.matterId}` as const;

    return route satisfies Route<typeof route>;
  }

  if (notification.notificationResource === ResourceEnum.MatterStep) {
    const route = `/dashboard/matters/${notification.matterId}/progress/steps/${notification.matterStepId}` as const;

    return route satisfies Route<typeof route>;
  }

  if (notification.notificationResource === ResourceEnum.Document) {
    const route = `/dashboard/matters/${notification.matterId}/documents/${notification.documentId}` as const;

    return route satisfies Route<typeof route>;
  }

  if (notification.notificationResource === ResourceEnum.Enquiry) {
    if (notification.documentId) {
      const route = `/dashboard/matters/${notification.matterId}/documents/${notification.documentId}` as const;

      return route satisfies Route<typeof route>;
    }

    if (notification.matterStepId) {
      const route = `/dashboard/matters/${notification.matterId}/progress/steps/${notification.matterStepId}` as const;

      return route satisfies Route<typeof route>;
    }

    const route = `/dashboard/matters/${notification.matterId}` as const;

    return route satisfies Route<typeof route>;
  }
};

const getContextLink = (notification: NotificationMeta): Route<any> | null => {
  if (notification.notificationResource === ResourceEnum.Document) {
    const route = `/dashboard/matters/${notification.matterId}` as const;

    return route satisfies Route<typeof route>;
  }

  if (notification.notificationResource === ResourceEnum.MatterStep) {
    const route = `/dashboard/matters/${notification.matterId}` as const;

    return route satisfies Route<typeof route>;
  }

  return null;
};

const getNotificationViewLink = (notification: NotificationMeta, userId: string): Route<any> | null => {
  const documentActions = [NotificationActionEnum.UploadedDocument];

  if (documentActions.includes(notification.notificationAction)) {
    const route = `/dashboard/matters/${notification.matterId}/documents/${notification.documentId}` as const;

    return route satisfies Route<typeof route>;
  }

  const enquiryActions = [
    NotificationActionEnum.CreatedEnquiry,
    NotificationActionEnum.UpdatedEnquiryStatus,
    NotificationActionEnum.RepliedToEnquiry,
  ];

  if (enquiryActions.includes(notification.notificationAction)) {
    if (notification.documentId) {
      const route = `/dashboard/matters/${notification.matterId}/documents/${notification.documentId}` as const;

      return route satisfies Route<typeof route>;
    }

    if (notification.matterStepId) {
      const route = `/dashboard/matters/${notification.matterId}/progress/steps/${notification.matterStepId}` as const;

      return route satisfies Route<typeof route>;
    }

    const route = `/dashboard/matters/${notification.matterId}/enquiries/${notification.enquiryId}` as const;

    return route satisfies Route<typeof route>;
  }

  const userInviteActions = [NotificationActionEnum.InvitedYou];

  if (userInviteActions.includes(notification.notificationAction)) {
    const route = `/dashboard/users/${userId}/invites` as const;

    return route satisfies Route<typeof route>;
  }

  const entityInviteActions = [
    NotificationActionEnum.AcceptedInvite,
    NotificationActionEnum.InviteRequest,
    NotificationActionEnum.RejectedInvite,
  ];

  if (entityInviteActions.includes(notification.notificationAction)) {
    if (notification.notificationResource === ResourceEnum.Organisation) {
      const route = `/dashboard/organisations/${notification.organisationId}/members` as const;

      return route satisfies Route<typeof route>;
    }

    if (notification.notificationResource === ResourceEnum.Matter) {
      const route = `/dashboard/matters/${notification.matterId}/members` as const;

      return route satisfies Route<typeof route>;
    }
  }

  const matterStepActions = [
    NotificationActionEnum.AssignedYou,
    NotificationActionEnum.SubmittedMatterStepForm,
    NotificationActionEnum.UpdatedMatterStepStatus,
  ];

  if (matterStepActions.includes(notification.notificationAction)) {
    const route = `/dashboard/matters/${notification.matterId}/progress/steps/${notification.matterStepId}` as const;

    return route satisfies Route<typeof route>;
  }

  const matterActions = [NotificationActionEnum.ClosedMatter];

  if (matterActions.includes(notification.notificationAction)) {
    const route = `/dashboard/matters/${notification.matterId}` as const;

    return route satisfies Route<typeof route>;
  }
};

const getEntityName = (notification: NotificationMeta): string => {
  if (notification.notificationResource === ResourceEnum.Organisation) {
    return notification.organisationProfile?.organisation?.name ?? 'organisation';
  }

  if (notification.notificationResource === ResourceEnum.Matter) {
    return notification?.matter?.name ?? 'matter';
  }

  if (notification.notificationResource === ResourceEnum.MatterStep) {
    return notification?.matterStep?.step?.name ?? 'matter step';
  }

  if (notification.notificationResource === ResourceEnum.Document) {
    return notification.document?.name ?? 'document';
  }

  if (notification.notificationResource === ResourceEnum.Enquiry) {
    return notification.matter?.name ?? 'matter';
  }

  return 'link';
};

const getContextName = (notification: NotificationMeta): string => {
  const matterContextActions = [
    NotificationActionEnum.AssignedYou,
    NotificationActionEnum.UploadedDocument,
    NotificationActionEnum.SubmittedMatterStepForm,
    NotificationActionEnum.UpdatedMatterStepStatus,
  ];

  if (matterContextActions.includes(notification.notificationAction)) {
    return notification.matter?.name ?? 'matter';
  }

  return '';
};

const getActionDescription = (action: NotificationActionEnum): string => {
  if (action === NotificationActionEnum.AcceptedInvite) {
    return 'accepted invite to join';
  }
  if (action === NotificationActionEnum.AcceptedInviteRequest) {
    return 'accepted your invite request to join';
  }
  if (action === NotificationActionEnum.AssignedYou) {
    return 'assigned you to';
  }
  if (action === NotificationActionEnum.ClosedMatter) {
    return 'closed matter';
  }
  if (action === NotificationActionEnum.CreatedEnquiry) {
    return 'created an enquiry on';
  }
  if (action === NotificationActionEnum.InviteRequest) {
    return 'requested an invite to join';
  }
  if (action === NotificationActionEnum.InvitedYou) {
    return 'invited you to join';
  }
  if (action === NotificationActionEnum.RejectedInvite) {
    return 'rejected your invitation to join';
  }
  if (action === NotificationActionEnum.RejectedInviteRequest) {
    return 'rejected your invitation request to join';
  }
  if (action === NotificationActionEnum.RepliedToEnquiry) {
    return 'replied to enquiry on';
  }
  if (action === NotificationActionEnum.SubmittedMatterStepForm) {
    return 'submitted response to form';
  }
  if (action === NotificationActionEnum.UpdatedEnquiryStatus) {
    return 'updated enquiry status on';
  }
  if (action === NotificationActionEnum.UpdatedMatterStepStatus) {
    return 'updated status of step';
  }
  if (action === NotificationActionEnum.UploadedDocument) {
    return 'uploaded document';
  }

  return 'did something to';
};

const getContextDescription = (notification: NotificationMeta): string => {
  const showContextActions = [
    NotificationActionEnum.AssignedYou,
    NotificationActionEnum.SubmittedMatterStepForm,
    NotificationActionEnum.UpdatedMatterStepStatus,
    NotificationActionEnum.UploadedDocument,
  ];

  if (showContextActions.includes(notification.notificationAction)) {
    return 'on';
  }

  return '';
};

const shouldShowContext = (notification: NotificationMeta): boolean => {
  const showContextActions = [
    NotificationActionEnum.AssignedYou,
    NotificationActionEnum.SubmittedMatterStepForm,
    NotificationActionEnum.UpdatedMatterStepStatus,
    NotificationActionEnum.UploadedDocument,
  ];

  return showContextActions.includes(notification.notificationAction);
};

export const NotificationAlert: FC<NotificationProps> = ({ notification, focusRef, tourId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useRouter();
  const { mutate } = useSWRConfig();

  const userProfile = useContext(UserProfileContext);

  const adjustedDateTime = adjustUTCDateToLocalTimezone(new Date(notification.createdAt));

  const relativeDate = getRelativeTimeString(adjustedDateTime);

  const formattedDate = getFormattedDateAndTimeString(adjustedDateTime);

  const userName = getUserFullName(notification.userProfile.user);

  const notificationViewLink = getNotificationViewLink(notification, userProfile?.userId ?? '');

  const showContext = shouldShowContext(notification);

  const onNotificationDismiss = async () => {
    setIsLoading(true);

    try {
      const res = await dismissNotification(notification.id);

      if (res?.error) {
        throw res.error;
      }

      toast.success(`Notification dismissed`, {
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    mutate(notificationsTag());

    setIsLoading(false);
    router.refresh();
  };

  return (
    <Alert data-tour-id={tourId} className='relative'>
      {notification.userProfile && (
        <div className='-ml-5 -mt-5'>
          <UserAvatar className='h-8 w-8' userProfile={notification.userProfile} focusRef={focusRef} />
        </div>
      )}

      <div className='-mt-4 mb-1 flex w-full justify-end'>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Typography variant='mutedText' as='span'>
                {relativeDate}
              </Typography>
            </TooltipTrigger>
            <TooltipContent>{formattedDate}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>

      <AlertDescription>
        <p>
          <Link href={`/dashboard/users/${notification.userProfile.userId}`} className='font-bold hover:underline'>
            {userName} &nbsp;
          </Link>
          {getActionDescription(notification.notificationAction)}&nbsp;
          <Link href={getEntityLink(notification)} className='font-bold hover:underline'>
            {getEntityName(notification)}&nbsp;
          </Link>
          {showContext ? (
            <>
              {getContextDescription(notification)}&nbsp;
              <Link href={getContextLink(notification)} className='font-bold hover:underline'>
                {getContextName(notification)}&nbsp;
              </Link>
            </>
          ) : null}
        </p>

        <div className='flex items-center justify-end space-x-2'>
          {notificationViewLink ? (
            <Link className='font-medium underline-offset-4 hover:underline' href={notificationViewLink}>
              view
            </Link>
          ) : null}

          {notification.dismissedAt === null ? (
            <Button
              className='text-foreground inline h-fit whitespace-normal p-0'
              variant='link'
              disabled={isLoading}
              onClick={() => {
                onNotificationDismiss();
              }}
            >
              dismiss
            </Button>
          ) : null}
        </div>
      </AlertDescription>
    </Alert>
  );
};

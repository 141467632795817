'use client';

import { Enquiry, EnquiryOverview } from '@/common';
import { Route } from 'next';
import Link from 'next/link';
import { FC } from 'react';
import { Typography } from 'ui-lib';
import { EnquiryOverviewHeader } from './EnquiryOverviewHeader';

interface EnquiryCardProps {
  enquiryOverview: EnquiryOverview;
  tourId?: string;
}

const getEnquiryViewLink = (enquiry: Enquiry): Route<any> => {
  if (enquiry.documentId) {
    const route =
      `/dashboard/matters/${enquiry.matterId}/documents/${enquiry.documentId}/#enquiry_${enquiry.id}` as const;

    return route satisfies Route<typeof route>;
  }

  if (enquiry.matterStepId) {
    const route =
      `/dashboard/matters/${enquiry.matterId}/progress/steps/${enquiry.matterStepId}/#enquiry_${enquiry.id}` as const;

    return route satisfies Route<typeof route>;
  }

  const route = `/dashboard/matters/${enquiry.matterId}/enquiries/${enquiry.id ?? ''}` as const;

  return route satisfies Route<typeof route>;
};

export const EnquiryOverviewCard: FC<EnquiryCardProps> = ({ enquiryOverview, tourId }) => {
  const { enquiry, createdByUserProfile, lastActivityAt, messageCount } = enquiryOverview;

  const enquiryViewLink = getEnquiryViewLink(enquiry);

  return (
    <div data-tour-id={tourId} className='bg-card text-card-foreground w-full rounded-md border px-4 py-2 shadow'>
      <EnquiryOverviewHeader
        enquiry={enquiry}
        createdByUserProfile={createdByUserProfile}
        lastActivityAt={lastActivityAt}
      />
      <Typography variant='smallText'>{enquiry.title}</Typography>
      <p>{enquiry.body}</p>

      <div className='flex items-center justify-between'>
        <Typography variant='mutedText'>Message count: {messageCount}</Typography>
        {enquiryViewLink ? (
          <Link className='font-medium underline-offset-4 hover:underline' href={enquiryViewLink}>
            view
          </Link>
        ) : null}
      </div>
      {/* TODO: Consider whether to show the most recent message here */}
    </div>
  );
};

import { cn } from '../../../utils';
import React from 'react';

export interface SpinnerProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  colour?: string;
  className?: string;
}

export const Spinner = ({ size = 24, colour = 'currentColor', className, ...props }: SpinnerProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      {...props}
      viewBox='0 0 24 24'
      fill='none'
      stroke={colour}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className={cn('animate-spin', className)}
    >
      <path d='M21 12a9 9 0 1 1-6.219-8.56' />
    </svg>
  );
};

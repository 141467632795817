'use client';

import { FC, useEffect } from 'react';
import { toast } from 'ui-lib';
import { ForbiddenError } from './ForbiddenError';
import { GenericError } from './GenericError';
import { getErrorText } from './utils';
import { toastTimeout } from '@/common';

export type ErrorWithToastProps = {
  statusCode: number;
  message: string;
};

export const ErrorWithToast: FC<ErrorWithToastProps> = ({ statusCode, message }) => {
  useEffect(() => {
    const { errorType } = getErrorText(statusCode);

    toast.error(errorType, {
      description: message,
      dismissible: true,
      closeButton: true,
      duration: toastTimeout,
    });
  }, [statusCode, message]);

  const { errorType, errorDescription } = getErrorText(statusCode);

  if (statusCode === 403) {
    return <ForbiddenError errorType={errorType} errorDescription={errorDescription} />;
  }

  return <GenericError errorType={errorType} errorDescription={errorDescription} message={message} />;
};

'use client';

import { Route } from 'next';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { cn } from 'ui-lib';

interface NavLinks {
  href: Route<any>; // https://github.com/vercel/next.js/issues/47689
  label: string;
  tourId: string;
}

const navLinks: NavLinks[] = [
  {
    href: '/dashboard' satisfies Route<'/dashboard'>,
    label: 'Dashboard',
    tourId: 'navbar-dashboard',
  },
  {
    href: '/dashboard/matters' satisfies Route<'/dashboard/matters'>,
    label: 'Matters',
    tourId: 'navbar-matters',
  },
  {
    href: '/dashboard/organisations' satisfies Route<'/dashboard/organisations'>,
    label: 'Organisation',
    tourId: 'navbar-organisation',
  },
];

const muteNavLink = (href: string, pathname: string): boolean => {
  if (href === '/dashboard' && pathname.includes('/dashboard/matters/')) {
    return false;
  }

  return href !== pathname;
};

export function PlatformNavLinks({ className, ...props }: React.HTMLAttributes<HTMLElement>) {
  const pathname = usePathname();

  return (
    <nav
      className={cn(
        'flex flex-col items-start space-y-2 md:flex-row md:items-center md:space-x-4 md:space-y-0 lg:space-x-6',
        className,
      )}
      {...props}
    >
      {navLinks.map(({ href, label, tourId }) => (
        <Link
          href={href}
          key={`nav_link_${href}`}
          data-tour-id={tourId}
          className={cn('hover:text-primary text-sm font-medium transition-colors', {
            'text-muted-foreground': muteNavLink(href, pathname ?? ''),
          })}
        >
          {label}
        </Link>
      ))}
    </nav>
  );
}

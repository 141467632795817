'use client';

import { messagesTag } from '@/common';
import { FC, useState } from 'react';
import { useSWRConfig } from 'swr';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  PaperclipIcon,
} from 'ui-lib';
import { DocumentUploadForm } from '../Documents';
import { useRouter } from 'next/navigation';

interface EnquiryChatUploadModalProps {
  matterId: string;
  enquiryId: string;
  canCreateDocument: boolean;
}

export const EnquiryChatUploadModal: FC<EnquiryChatUploadModalProps> = ({ enquiryId, matterId, canCreateDocument }) => {
  const router = useRouter();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { mutate } = useSWRConfig();

  if (!canCreateDocument) {
    return null;
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button type='button' variant='ghost' size='icon'>
          <PaperclipIcon className='h-4 w-4' />
        </Button>
      </DialogTrigger>
      <DialogContent className='max-h-[80vh] overflow-y-scroll'>
        <DialogHeader>
          <DialogTitle>Upload documents</DialogTitle>
          <DialogDescription>Upload documents to enquiry</DialogDescription>
        </DialogHeader>

        <DocumentUploadForm
          type='enquiry'
          enquiryId={enquiryId}
          matterId={matterId}
          onComplete={() => {
            setDialogOpen(false);
            mutate(messagesTag());
            router.refresh();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

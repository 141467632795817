'use client';

import { updateMatterUserRole, updateOrganisationUserRole } from '@/actions';
import { MatterRoleEnum, OrganisationRoleEnum, ResourceEnum, toastTimeout } from '@/common';
import { RoleSelectRole } from '@/common/types';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import {
  Button,
  Card,
  ChevronDownIcon,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  cn,
  toast,
} from 'ui-lib';

type MatterMemberRoleSelectProps = {
  type: ResourceEnum.Matter;
  matterId: string;
  userId: string;
  fullName: string;
  roleSelects: RoleSelectRole<MatterRoleEnum>[];
  currentRole: MatterRoleEnum;
  canEdit: boolean;
};

type OrganisationMemberRoleSelectProps = {
  type: ResourceEnum.Organisation;
  organisationId: string;
  userId: string;
  fullName: string;
  roleSelects: RoleSelectRole<OrganisationRoleEnum>[];
  currentRole: OrganisationRoleEnum;
  canEdit: boolean;
};

type MemberRoleSelectProps = MatterMemberRoleSelectProps | OrganisationMemberRoleSelectProps;

export const MemberRoleSelect = ({
  userId,
  fullName,
  roleSelects,
  currentRole,
  canEdit,
  ...props
}: MemberRoleSelectProps) => {
  const currentRoleLabel = roleSelects?.find?.(({ role }) => role === currentRole)?.label ?? '';

  const router = useRouter();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!canEdit) {
    return <Card className='px-4 py-2 text-sm font-medium shadow-sm'>{currentRoleLabel}</Card>;
  }

  const onSelect = async (role: MatterRoleEnum | OrganisationRoleEnum) => {
    setIsLoading(true);

    try {
      if (props.type === ResourceEnum.Organisation) {
        const res = await updateOrganisationUserRole(props.organisationId, userId, role as OrganisationRoleEnum);

        if (res?.error) {
          throw res.error;
        }
      } else if (props.type === ResourceEnum.Matter) {
        const res = await updateMatterUserRole(props.matterId, userId, role as MatterRoleEnum);

        if (res?.error) {
          throw res.error;
        }
      } else {
        alert('TODO!');
      }

      router.refresh();

      toast.success(`Role updated`, {
        description: `${fullName} role changed to ${role}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    setIsLoading(false);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant='outline' className='ml-auto'>
          {currentRoleLabel} <ChevronDownIcon className='text-muted-foreground ml-2 h-4 w-4' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='p-0' align='end'>
        <Command>
          <CommandInput placeholder='Select new role...' />
          <CommandList>
            <CommandEmpty>No roles found.</CommandEmpty>
            <CommandGroup>
              {roleSelects.map((roleSelect) => (
                <CommandItem
                  key={`role_select_item_user_${userId}_role_${roleSelect.role}`}
                  className={cn('flex cursor-pointer flex-col items-start space-y-1 px-4 py-2', {
                    'cursor-wait': isLoading,
                  })}
                  disabled={isLoading}
                  onSelect={() => {
                    onSelect(roleSelect.role as OrganisationRoleEnum);
                  }}
                >
                  <p>{roleSelect.label}</p>
                  <p className='text-muted-foreground text-sm'>{roleSelect.description}</p>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

'use client';

import { FC, Ref } from 'react';

import { NotificationMeta } from '@/common';
import { Typography } from 'ui-lib';
import { NotificationAlert } from './NotificationAlert';

export interface NotificationListProps {
  notifications: NotificationMeta[];
  popoverFocusRef: Ref<any>;
}

export const NotificationList: FC<NotificationListProps> = ({ notifications, popoverFocusRef }) => {
  if (!notifications.length) {
    return (
      <div className=''>
        <Typography variant='mutedText' as='p'>
          No notifications
        </Typography>
      </div>
    );
  }

  return (
    <>
      {notifications.map((notification, idx) => (
        <NotificationAlert
          key={`notification_${notification.id}`}
          notification={notification}
          focusRef={idx === 0 ? popoverFocusRef : null}
        />
      ))}
    </>
  );
};

import { FC } from 'react';
import { Address as AddressData } from '@/common';
import { cn } from 'ui-lib';

export type AddressProps = {
  address?: AddressData;
} & React.HTMLAttributes<HTMLParagraphElement>;

export const Address: FC<AddressProps> = ({ address, className }) => {
  return (
    <p className={cn(className)}>
      {address?.line1?.length ? (
        <>
          {address?.line1}
          <br />
        </>
      ) : null}
      {address?.line2?.length ? (
        <>
          {address?.line2}
          <br />
        </>
      ) : null}
      {address?.line3?.length ? (
        <>
          {address?.line3}
          <br />
        </>
      ) : null}
      {address?.city?.length ? (
        <>
          {address?.city}
          <br />
        </>
      ) : null}
      {address?.county?.length ? (
        <>
          {address?.county}
          <br />
        </>
      ) : null}
      {address?.postcode?.length ? (
        <>
          {address?.postcode}
          <br />
        </>
      ) : null}
    </p>
  );
};

'use client';

import { Document, UserProfile } from '@/common';
import { Route } from 'next';
import Link from 'next/link';
import { FC } from 'react';
import { Typography } from 'ui-lib';
import { DocumentOverviewHeader } from './DocumentOverviewHeader';

type DocumentOverviewCardProps = {
  document: Document;
  createdByUserProfile: UserProfile;
  enquiryCount: number;
  openEnquiryCount: number;
  lastActivityAt: Date;
  tourId?: string;
};

const getDocumentViewLink = (document: Document): Route<any> => {
  const route = `/dashboard/matters/${document.matterId}/documents/${document.id}` as const;

  return route satisfies Route<typeof route>;
};

export const DocumentOverviewCard: FC<DocumentOverviewCardProps> = (props) => {
  const document = props.document;

  const documentViewLink = getDocumentViewLink(document);

  return (
    <div
      data-tour-id={props.tourId}
      className='bg-card text-card-foreground w-full rounded-md border px-4 py-2 pt-4 shadow'
    >
      <DocumentOverviewHeader
        className='pb-0'
        document={document}
        createdByUserProfile={props.createdByUserProfile}
        lastActivityAt={props.lastActivityAt}
      />

      <div className='flex items-center justify-between'>
        <div className='flex items-start justify-start space-x-2'>
          <Typography variant='mutedText'>Enquiries: {props.enquiryCount}</Typography>
          <Typography variant='mutedText'>Open Enquiries: {props.openEnquiryCount}</Typography>
        </div>

        <Link className='font-medium underline-offset-4 hover:underline' href={documentViewLink}>
          view
        </Link>
      </div>
    </div>
  );
};

import { Route } from 'next';
import Link from 'next/link';
import { FC } from 'react';
import { Card, CardContent, CardHeader, CardTitle, cn } from 'ui-lib';

export type StatCardProps = {
  title: string;
  value: string | number;
  icon?: JSX.Element;
  href?: Route<string>;
  tourId?: string;
};

export const StatCard: FC<StatCardProps> = ({ title, value, href, tourId }) => {
  return (
    <Card data-tour-id={tourId ?? ''}>
      <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
        <CardTitle className='text-sm font-medium'>{title}</CardTitle>
      </CardHeader>
      <CardContent
        className={cn({
          'pb-3': href?.length && href.length > 0,
        })}
      >
        <div className='text-2xl font-bold'>{value}</div>
        {href ? (
          <div className='flex w-full items-center justify-end'>
            <Link href={href} className='font-medium underline-offset-4 hover:underline'>
              view
            </Link>
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
};

import { OrganisationMember, OrganisationRoleEnum, ResourceEnum } from '@/common';
import { RoleSelectRole } from '@/common/types';
import { FC } from 'react';
import { Typography } from 'ui-lib';
import { MemberCard } from '../Members';

const roleSelects: RoleSelectRole<OrganisationRoleEnum>[] = [
  {
    role: OrganisationRoleEnum.Manager,
    label: 'Manager',
    description: 'Managers can make changes to organisations and members',
  },
  {
    role: OrganisationRoleEnum.Member,
    label: 'Member',
    description: 'Members have restricted access',
  },
];

export interface OrganisationMembersListProps {
  organisationId: string;
  organisationMembers: OrganisationMember[];
  canEdit: boolean;
  tourId?: string;
}

export const OrganisationMembersList: FC<OrganisationMembersListProps> = ({
  organisationId,
  organisationMembers,
  canEdit,
  tourId,
}) => {
  return (
    <>
      <Typography data-tour-id={tourId} as='h3' variant='h3' className='pt-2'>
        Active Members
      </Typography>
      <div className='flex flex-col gap-4 py-6'>
        {organisationMembers.map((member) => (
          <MemberCard
            key={`member_card_${member.userProfile?.userId}`}
            type={ResourceEnum.Organisation}
            roleSelects={roleSelects}
            organisationId={organisationId}
            userProfile={member.userProfile}
            currentRole={member.role as OrganisationRoleEnum}
            canEdit={canEdit}
          />
        ))}
      </div>
    </>
  );
};

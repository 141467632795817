'use client';

import { Enquiry, EnquiryStatusEnum, UserProfile } from '@/common';
import { FC } from 'react';
import { Badge, Typography } from 'ui-lib';
import { EnquiryMessageHeader } from './EnquiryMessageHeader';
import { EnquiryReplyCard } from './EnquiryReplyCard';
import { EnquiryUpdateStatusDialog } from './EnquiryUpdateStatusDialog';
import { getEnquiryBadgeVariant, getEnquiryStatusText } from './utils';

interface EnquiryCardProps {
  enquiry: Enquiry;
  userProfile: UserProfile;
  canEdit: boolean;
  canMessage: boolean;
  children?: React.ReactNode;
}

export const EnquiryCard: FC<EnquiryCardProps> = ({ canMessage, canEdit, enquiry, userProfile, children }) => {
  const canReply = canMessage && enquiry.status !== EnquiryStatusEnum.Resolved;

  const statusText = getEnquiryStatusText(enquiry);
  const badgeVariant = getEnquiryBadgeVariant(enquiry);

  return (
    <div
      id={`enquiry_${enquiry.id}`}
      className='bg-card text-card-foreground w-full rounded-md border px-4 py-2 shadow'
    >
      <EnquiryMessageHeader userProfile={userProfile} createdAt={enquiry.createdAt} createdById={enquiry.createdById} />
      <Typography variant='smallText'>{enquiry.title}</Typography>
      <p>{enquiry.body}</p>
      <div className='flex flex-row items-center justify-between pt-2'>
        {canEdit ? <EnquiryUpdateStatusDialog enquiry={enquiry} /> : <div />}
        <Badge variant={badgeVariant}>{statusText}</Badge>
      </div>
      {children}
      {canReply ? <EnquiryReplyCard enquiry={enquiry} /> : null}
    </div>
  );
};

'use client';

import { FC, useState } from 'react';
import { Button, Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from 'ui-lib';
import { DocumentUploadForm } from '../Documents';

interface DocumentUploadModalProps {
  matterId: string;
  matterStepId?: string;
  formFieldId?: string;
  canCreateDocument: boolean;
}

export const DocumentUploadModal: FC<DocumentUploadModalProps> = ({
  matterId,
  matterStepId,
  formFieldId,
  canCreateDocument,
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  if (!canCreateDocument) {
    return null;
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button data-tour-id='documents-add-button' type='button'>
          Add Documents
        </Button>
      </DialogTrigger>
      <DialogContent className='max-h-[80vh] overflow-y-scroll'>
        <DialogHeader>
          <DialogTitle>Upload documents</DialogTitle>
          <DialogDescription>Upload documents to matter</DialogDescription>
        </DialogHeader>

        <DocumentUploadForm
          type='matter'
          matterId={matterId}
          matterStepId={matterStepId}
          formFieldId={formFieldId}
          onComplete={() => {
            setDialogOpen(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

'use client';

import { UserProfile, getUserFullName } from '@/common';
import { Route } from 'next';
import Link from 'next/link';
import { FC } from 'react';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'ui-lib';
import { UserAvatar } from '../UserAvatar/UserAvatar';

export const dynamic = 'force-dynamic';

export interface UserAvatarMenuProps {
  userProfile: UserProfile;
  signOutUrl: string;
}

export const UserAvatarMenu: FC<UserAvatarMenuProps> = ({ userProfile, signOutUrl }) => {
  const userName = getUserFullName(userProfile?.user);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button data-tour-id='navbar-avatar' variant='ghost' className='relative h-8 w-8 rounded-full'>
          <UserAvatar className='h-8 w-8' userProfile={userProfile} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-56' align='end' forceMount>
        <DropdownMenuLabel className='font-normal'>
          <div className='flex flex-col space-y-1'>
            <p className='text-sm font-medium leading-none'>{userName}</p>
            <p className='text-muted-foreground text-xs leading-none'>{userProfile?.user?.email}</p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>
            <Link className='w-full' href={`/dashboard/users/${userProfile?.userId}`}>
              Profile
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link className='w-full' href={`/dashboard/users/${userProfile?.userId}/invites`}>
              Invites
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link className='w-full' href={`/dashboard/users/${userProfile?.userId}/settings`}>
              Settings
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <Link className='w-full' href={signOutUrl as Route} prefetch={false}>
            Log out
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

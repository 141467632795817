import { FC } from 'react';
import { Alert, AlertDescription, AlertTitle, InfoCircledIcon, cn } from 'ui-lib';

export type InvitesCountProps = {
  count: number;
  className?: string;
  tourId?: string;
};

export const InvitesCount: FC<InvitesCountProps> = ({ className, count, tourId }) => {
  return (
    <div data-tour-id={tourId} className={cn('', className)}>
      <Alert>
        <InfoCircledIcon className='h-4 w-4' />
        <AlertTitle>{count === 0 ? 'No invites' : 'Invites pending'}</AlertTitle>
        <AlertDescription>
          You have <span className='font-semibold'>{count}</span> invite{count === 1 ? '' : 's'} pending
        </AlertDescription>
      </Alert>
    </div>
  );
};

'use client';

import { cancelInvite } from '@/actions';
import { toastTimeout, User } from '@/common';
import { useRouter } from 'next/navigation';
import { FC } from 'react';
import { Button, Card, toast } from 'ui-lib';

interface OrganisationInviteCardProps {
  inviteId: string;
  canManageInvites: boolean;
  user?: User;
}

export const OrganisationInviteCard: FC<OrganisationInviteCardProps> = ({ inviteId, canManageInvites, user }) => {
  const router = useRouter();

  if (!user) {
    return null;
  }

  const onCancelInvite = async (inviteId: string) => {
    try {
      const res = await cancelInvite(inviteId);

      if (res?.error) {
        throw res.error;
      }

      toast.info(`Invite cancelled`, {
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    } catch (e) {
      toast.error(`Error`, {
        description: `${e.message}`,
        closeButton: true,
        duration: toastTimeout,
        invert: true,
      });
    }

    router.refresh();
  };

  return (
    <Card className='grid grid-cols-1 p-4 sm:flex sm:flex-row sm:items-center sm:justify-between sm:space-x-6'>
      <div className='flex flex-col'>
        <p className='text-sm font-medium leading-none'>{user.email}</p>
      </div>
      <Button
        variant={'destructive'}
        disabled={!canManageInvites}
        onClick={() => {
          onCancelInvite(inviteId);
        }}
      >
        Cancel Invite
      </Button>
    </Card>
  );
};
